import {
  SuperRareBazaarAbi,
  getSuperRareBazaar,
} from "@/contracts/superrarebazaar"
import { useMemo } from "react"
import { useAccount, useWriteContract } from "wagmi"
import { useIsContractApproved } from "./useIsApproved"
import { genToastId } from "@/utils/toast"
import toast from "react-hot-toast"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { useSetMaxAllowance } from "./useSetMaxAllowance"
import { zeroAddress } from "viem"
import { TxToastError } from "@/utils/txUtils"
import { useTransactionModalStore } from "@/stores/transactionModal"

export type AuctionToken = Pick<
  Erc721Token,
  "contract_address" | "token_id" | "erc721_owner"
>
export type AuctionType = "COLDIE_AUCTION" | "SCHEDULED_AUCTION"
export const hexAuctionTypes = {
  COLDIE_AUCTION:
    "0x434f4c4449455f41554354494f4e000000000000000000000000000000000000" as `0x${string}`,
  SCHEDULED_AUCTION:
    "0x5343484544554c45445f41554354494f4e000000000000000000000000000000" as `0x${string}`,
}
export type SubmitSplits = {
  splitAddresses: `0x${string}`[]
  splitRatios: number[]
  totalRatios: number
}
export const useConfigureAuction = ({
  token,
  startingWeiPrice,
  auctionType,
  currencyAddress,
  startTime,
  endingTime,
  submitSplits,
}: {
  token: AuctionToken
  marketAddress?: string
  startingWeiPrice: number
  auctionType: AuctionType
  currencyAddress: `0x${string}`
  startTime?: number
  endingTime?: number
  submitSplits?: SubmitSplits
}): {
  configureAuction: (onSubmit?: () => void) => void
  isLoading: boolean
  isApproved: boolean
} => {
  const { address, chain } = useAccount()
  const { setHash, setToastId } = useTransactionModalStore()
  const bazaarContract = chain?.id ? getSuperRareBazaar(chain?.id) : "0x"
  const isColdieAuction = auctionType === "COLDIE_AUCTION"
  // 24 hours or 15 minutes
  const coldieDuration = chain?.id === 1 ? BigInt(86400) : BigInt(900)
  const lengthOfAuctionMiliseconds = useMemo(() => {
    if (isColdieAuction) return coldieDuration
    return endingTime && startTime
      ? BigInt(endingTime - startTime)
      : coldieDuration
  }, [endingTime, startTime, auctionType])
  const splitRatios = [100]
  const splitAddresses = [token?.erc721_owner?.owner_address as "0x{string}"]
  const isEth = currencyAddress === zeroAddress

  const splitsToSubmit = useMemo(
    () => ({
      splitAddresses: submitSplits?.splitAddresses
        ? [...submitSplits?.splitAddresses, splitAddresses[0]]
        : splitAddresses,
      splitRatios: submitSplits?.splitRatios
        ? [
            ...submitSplits?.splitRatios,
            splitRatios[0] - submitSplits.totalRatios,
          ]
        : splitRatios,
    }),
    [submitSplits]
  )

  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const {
    isApproved,
    approve,
    isLoading: isLoadingIsApproved,
  } = useIsContractApproved({
    ownerAddress: address as string,
    contractAddress: token?.contract_address as string,
  })

  const { allowance, isLoadingAllowance, setMaxAllowance } = useSetMaxAllowance(
    bazaarContract,
    isApproved && !!address && !isEth
  )

  const configureAuction = async (onSubmit?: () => void): Promise<void> => {
    const randomId = genToastId()
    try {
      if (!isApproved) {
        toast.loading("Approving contract...", { id: randomId })
        await approve()
        toast.success("Contract approved", { id: randomId })
        return
      }
      if ((!allowance || Number(allowance) < startingWeiPrice) && !isEth) {
        toast.loading("Approving allowance...", { id: randomId })
        await setMaxAllowance()
        return
      }
      if (!writeContractAsync) {
        toast.error(
          "Error: writeContractAsync " + errorContract,
          writeContractAsync
        )
        return
      }
      toast.loading("Awaiting user confirmation...", { id: randomId })
      const result = await writeContractAsync({
        address: bazaarContract as `0x${string}`,
        abi: SuperRareBazaarAbi,
        functionName: "configureAuction",
        chainId: chain?.id,
        args: [
          hexAuctionTypes[auctionType],
          token?.contract_address as `0x${string}`,
          BigInt(token?.token_id),
          BigInt(startingWeiPrice),
          currencyAddress,
          lengthOfAuctionMiliseconds,
          isColdieAuction ? BigInt(0) : BigInt(startTime || 0),
          splitsToSubmit?.splitAddresses,
          splitsToSubmit?.splitRatios,
        ],
      })
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return {
    configureAuction,
    isLoading: isPending || isLoadingIsApproved || isLoadingAllowance,
    isApproved,
  }
}
