import { Address } from "viem"
import useSWRMutation from "swr/mutation"

export type LoginParams = {
  signature: string | null
  address: Address | null
}

const postLogin = async (
  url: string,
  { arg: { signature, address } }: { arg: LoginParams }
) => {
  const response = await fetch(url, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      signature,
      address,
    }),
  })

  return response
}

export const useLogin = () => {
  const { trigger, data, error, isMutating, reset } = useSWRMutation(
    "/api/next/auth/login?cookies=true",
    postLogin
  )

  return { login: trigger, data, error, isMutating, reset }
}
