import RareToken from "@/abis/RareToken.json"
import { getRareCurrency } from "@/utils/currency"

import { useReadContract } from "wagmi"

export const useRareAllowance = (
  enabled: boolean,
  contractAddress: string,
  walletAddress?: string
): { allowance: bigint; isLoading: boolean; refetch: () => void } => {
  const { data, isLoading, refetch } = useReadContract({
    address: getRareCurrency().address,
    abi: RareToken,
    functionName: "allowance",
    args: [walletAddress, contractAddress],
  })
  const allowance = (data as bigint) || 0n
  return { allowance, isLoading, refetch }
}
