"use client"

import { useProfileStore } from "@/stores/profile"
import { loadHeap } from "@/utils/heap"
import * as React from "react"
import { useAccount } from "wagmi"

const Heap: React.FC = () => {
  const { isHeapLoaded, setIsHeapLoaded } = useProfileStore()
  const { isConnected } = useAccount()
  React.useEffect(() => {
    if (!isHeapLoaded && isConnected) {
      loadHeap()
      setIsHeapLoaded(true)
    }
  }, [isHeapLoaded, setIsHeapLoaded, isConnected])
  return null
}

export default Heap
