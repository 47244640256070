"use client"

import React, { useEffect } from "react"

interface GoogleAnalytics4ScriptProps {
  measurementId: string
}

const GoogleAnalytics4: React.FC<GoogleAnalytics4ScriptProps> = ({
  measurementId,
}) => {
  useEffect(() => {
    if (!measurementId) {
      return
    }

    // Add the GA4 global site tag to the component
    const script = document.createElement("script")
    script.async = true
    script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}`
    document.head.appendChild(script)

    // Add the inline script to initialize GA4
    const inlineScript = document.createElement("script")
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${measurementId}');
    `
    document.head.appendChild(inlineScript)

    return () => {
      // Cleanup scripts when the component unmounts
      document.head.removeChild(script)
      document.head.removeChild(inlineScript)
    }
  }, [measurementId])

  return null
}

export default GoogleAnalytics4
