"client only"
import React from "react"
import("@google/model-viewer")

export const ModelViewer = ({
  src,
  alt,
  className,
}: {
  src: string
  alt: string
  className?: string
}): JSX.Element => {
  return (
    <div
      className={className}
      style={{ width: "100%", height: "100%", minHeight: "300px" }}
    >
      <model-viewer
        src={src}
        alt={alt}
        camera-controls
        ios-src={src}
        ar
        auto-rotate
        style={{ width: "100%", height: "100%", minHeight: "300px" }}
      ></model-viewer>
    </div>
  )
}
