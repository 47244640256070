import { AlgoliaHit } from "@/utils/algolia"
import { create } from "zustand"

export type FilterValue = {
  [key: string]: any
}

interface ArtworksFilters {
  tags: { [key: string]: boolean }
  debutArtworks?: boolean | null
  hasSales?: boolean | null
  isNewArtist?: boolean | null
  currencyType?: string[]
  [key: string]: any
}

interface SeriesFilters {
  categories: { [key: string]: boolean }

  [key: string]: any
}

export enum ExploreOrder {
  prod_artworks_activityTimestampUnix_desc = "prod_artworks_activityTimestampUnix_desc",
  //prod_artworks_endingTimeUnix_desc = "prod_artworks_endingTimeUnix_desc",
  prod_artworks_salePriceEth_asc_HARD = "prod_artworks_salePriceEth_asc_HARD",
  prod_artworks_salePriceEth_desc_HARD = "prod_artworks_salePriceEth_desc_HARD",
  prod_artworks_nftDatetimeMintedTimestampUnix_desc = "prod_artworks_nftDatetimeMintedTimestampUnix_desc",
  prod_artworks_nftDatetimeMintedTimestampUnix_asc = "prod_artworks_nftDatetimeMintedTimestampUnix_asc",
}

const exploreOrderDisplayNames: { [key in ExploreOrder]: string } = {
  [ExploreOrder.prod_artworks_activityTimestampUnix_desc]: "Recently active",
  //[ExploreOrder.prod_artworks_endingTimeUnix_desc]: "Ending soon",
  [ExploreOrder.prod_artworks_salePriceEth_asc_HARD]: "Lowest price (ETH only)",
  [ExploreOrder.prod_artworks_salePriceEth_desc_HARD]:
    "Highest price (ETH only)",
  [ExploreOrder.prod_artworks_nftDatetimeMintedTimestampUnix_desc]: "Newest",
  [ExploreOrder.prod_artworks_nftDatetimeMintedTimestampUnix_asc]: "Oldest",
}

export enum SeriesOrder {
  prod_series_latestMintAtUnix_desc = "prod_series_latestMintAtUnix_desc",
  prod_series_saleVolumeEth_desc = "prod_series_saleVolumeEth_desc",
  prod_series_collectorCount_desc = "prod_series_collectorCount_desc",
  prod_series_artworks_desc = "prod_series_artworks_desc",
  prod_series_floorAmountEth_desc_HARD = "prod_series_floorAmountEth_desc_HARD",
  prod_series_floorAmountEth_asc_HARD = "prod_series_floorAmountEth_asc_HARD",
  prod_series_uniqueViews_desc = "prod_series_uniqueViews_desc",
}

const seriesOrderDisplayNames: { [key in SeriesOrder]: string } = {
  [SeriesOrder.prod_series_latestMintAtUnix_desc]: "Newest first",
  [SeriesOrder.prod_series_saleVolumeEth_desc]: "Highest sales",
  [SeriesOrder.prod_series_collectorCount_desc]: "Number of collectors",
  [SeriesOrder.prod_series_artworks_desc]: "Number of artworks",
  [SeriesOrder.prod_series_floorAmountEth_desc_HARD]: "Floor price (Highest)",
  [SeriesOrder.prod_series_floorAmountEth_asc_HARD]: "Floor price (Lowest)",
  [SeriesOrder.prod_series_uniqueViews_desc]: "Most viewed",
}

export enum ExploreType {
  artworks = "artworks",
  series = "series",
}

export function getOrderDisplayName(order: ExploreOrder): string {
  return exploreOrderDisplayNames[order]
}

export function getSeriesOrderDisplayName(order: SeriesOrder): string {
  return seriesOrderDisplayNames[order]
}

type ExploreStore = {
  type: "artworks" | "series"
  setType: (type: "artworks" | "series") => void
  setAsArtworks: () => void
  setAsSeries: () => void

  filters: {
    artworks: ArtworksFilters
    series: SeriesFilters
    search?: string
  }
  setFilters: (filters: {
    artworks?: ArtworksFilters
    series?: SeriesFilters
    search?: string
  }) => void

  hits: AlgoliaHit[]
  setHits: (hits: AlgoliaHit[]) => void

  orderBy: {
    artworks: ExploreOrder
    series: SeriesOrder
  }
  setOrderBy: (orderBy: ExploreOrder | SeriesOrder) => void

  isFiltersOpen: boolean
  setFiltersOpen: (isOpen: boolean) => void
  toggleFilters: () => void

  countActiveFilters: () => { artworksCount: number; seriesCount: number }
  clearFilters: (clearAll?: boolean) => void

  viewMode: {
    artworks: "grid" | "masonry"
    series: "grid"
  }
  setViewMode: (type: "artworks" | "series", mode: "grid" | "masonry") => void

  counter: number
  setCounter: (counter: number) => void
}

export const useExploreStore = create<ExploreStore>((set, get) => ({
  type: "artworks",
  setType: (type) => set({ type }),
  setAsArtworks: () => set({ type: "artworks" }),
  setAsSeries: () => set({ type: "series" }),
  filters: {
    artworks: {
      tags: {},
      debutArtworks: null,
      hasSales: null,
      isNewArtist: null,
    },
    series: {
      categories: {},
    },
    search: "",
  },
  setFilters: (filters) =>
    set((state) => ({ filters: { ...state.filters, ...filters } })),

  countActiveFilters: () => {
    const filters = get().filters
    let artworksCount = 0
    let seriesCount = 0

    const countFilters = (filterGroup: any) => {
      let count = 0
      Object.values(filterGroup).forEach((value) => {
        if (Array.isArray(value)) {
          count += value.length
        } else if (typeof value === "object" && value !== null) {
          count += countFilters(value)
        } else if (value) {
          count += 1
        }
      })
      return count
    }

    artworksCount = countFilters(filters.artworks)
    seriesCount = countFilters(filters.series)
    const searchCount = filters.search ? 1 : 0

    return {
      artworksCount: artworksCount + searchCount,
      seriesCount: seriesCount + searchCount,
    }
  },

  clearFilters: (clearAll = false) => {
    const type = get().type
    const filters = get().filters

    if (type === "artworks") {
      set({
        filters: {
          ...filters,
          artworks: {
            tags: {},
            debutArtworks: null,
            hasSales: null,
            isNewArtist: null,
          },
          search: "",
        },
      })
    } else if (type === "series") {
      set({ filters: { ...filters, series: { categories: {} }, search: "" } })
    }

    if (clearAll) {
      set({
        filters: {
          ...filters,
          artworks: {
            tags: {},
            debutArtworks: null,
            hasSales: null,
            isNewArtist: null,
          },
          search: "",
        },
      })
      set({ filters: { ...filters, series: { categories: {} }, search: "" } })
    }
  },

  hits: [],
  setHits: (hits) => set((state) => ({ ...state, hits })),

  orderBy: {
    artworks: ExploreOrder.prod_artworks_activityTimestampUnix_desc,
    series: SeriesOrder.prod_series_latestMintAtUnix_desc,
  },
  setOrderBy: (orderBy) => {
    if (get().type === "artworks") {
      set({ orderBy: { ...get().orderBy, artworks: orderBy as ExploreOrder } })
    } else {
      set({ orderBy: { ...get().orderBy, series: orderBy as SeriesOrder } })
    }
  },

  isFiltersOpen: true,
  setFiltersOpen: (isOpen) => set({ isFiltersOpen: isOpen }),
  toggleFilters: () =>
    set((state) => ({ isFiltersOpen: !state.isFiltersOpen })),

  viewMode: {
    artworks: "masonry",
    series: "grid",
  },
  setViewMode: (type, mode) => {
    set((state) => ({
      viewMode: {
        ...state.viewMode,
        [type]: mode,
      },
    }))
  },

  counter: 0,
  setCounter: (counter) => set({ counter }),
}))
