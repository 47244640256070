import { abi } from "@/abis/SuperRareWhitelist"
import { Address } from "@/utils/common.types"

///////////////////////////////////////////////////////////////////////////////
// Addresses
///////////////////////////////////////////////////////////////////////////////
export const MAINNET_SUPERRARE_WHITELIST =
  "0xb932a70a57673d89f4acffbe830e8ed7f75fb9e0"
export const SEPOLIA_SUPERRARE_WHITELIST =
  "0x6c7c4879dd37bdf2b57f128b344def62da0ca34e"

///////////////////////////////////////////////////////////////////////////////
// getSuperRareWhitelist
///////////////////////////////////////////////////////////////////////////////
export function getSuperRareWhitelist(chainId: number): Address {
  if (chainId === 1) {
    return Address.parse(MAINNET_SUPERRARE_WHITELIST)
  }
  if (chainId === 11155111) {
    return Address.parse(SEPOLIA_SUPERRARE_WHITELIST)
  }
  throw new Error("Unsupported chain")
}

///////////////////////////////////////////////////////////////////////////////
// SuperRareWhitelistAbi
///////////////////////////////////////////////////////////////////////////////
export const SuperRareWhitelistAbi = abi
