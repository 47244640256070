import { useEffect, useState } from "react"
import { useVerifyWalletOwner } from "./useVerifyWalletOwner"
import { useLogin } from "./useLogin"
import { useSRDisconnect } from "./useSRDisconnect"
import { useAccount } from "wagmi"
import { match, P } from "ts-pattern"
import { useIsAuthenticated } from "@/hooks/useIsAuthenticated"

type SRConnectState =
  | "DISCONNECTED"
  | "WALLET_CONNECTING"
  | "WALLET_CONNECTED"
  | "PRE_AUTHENTICATING"
  | "PRE_AUTHENTICATED"
  | "SIGNING"
  | "SIGNED"
  | "LOGGING_IN"
  | "LOGGED_IN"
  | "LOGIN_ERROR"
  | "CONNECTED"
  | "PRE_AUTHENTICATION_ERROR"
  | "SIGN_ERROR"

export const useSRConnect = () => {
  const {
    signature,
    address,
    isSuccess: isVerifyWalletOwnerSuccess,
    isError: isVerifyWalletOwnerError,
    signMessage,
    reset: resetVerifyWalletOwner,
  } = useVerifyWalletOwner()
  const {
    data: loginData,
    error: loginError,
    login,
    isMutating: isLoginMutating,
    reset: resetLogin,
  } = useLogin()
  const {
    authenticate,
    data: authenticationData,
    error: authenticationError,
    isMutating: isAuthPending,
    reset: resetAuth,
  } = useIsAuthenticated()
  const { isConnected, isDisconnected } = useAccount()
  const { disconnect } = useSRDisconnect()
  const [srConnectionState, setSRConnectionState] =
    useState<SRConnectState>("DISCONNECTED")

  const reset = () => {
    resetAuth()
    resetLogin()
    resetVerifyWalletOwner()
  }

  useEffect(() => {
    if (isDisconnected) {
      setSRConnectionState("DISCONNECTED")
    }
  }, [isDisconnected])

  useEffect(() => {
    match(srConnectionState)
      .with("DISCONNECTED", () => {
        reset()
      })
      .with("WALLET_CONNECTED", () => {
        authenticate()
        setSRConnectionState("PRE_AUTHENTICATING")
      })
      .with("PRE_AUTHENTICATING", () => {
        match([isAuthPending, authenticationData, authenticationError])
          .with([true, P.any, P.any], () => {})
          .with([false, "UNAUTHENTICATED", P.any], () => {
            setSRConnectionState("PRE_AUTHENTICATION_ERROR")
          })
          .with([false, P.any, P.nonNullable], () => {
            setSRConnectionState("PRE_AUTHENTICATION_ERROR")
          })
          .with([false, P.nonNullable, P.nullish], () => {
            setSRConnectionState("PRE_AUTHENTICATED")
          })
          .with([false, P.nullish, P.nullish], () => {})
          .run()
      })
      .with("PRE_AUTHENTICATED", () => {
        setSRConnectionState("CONNECTED")
      })
      .with("PRE_AUTHENTICATION_ERROR", () => {
        signMessage()
        setSRConnectionState("SIGNING")
      })
      .with("SIGNING", () => {
        match([isVerifyWalletOwnerSuccess, isVerifyWalletOwnerError])
          .with([true, false], () => {
            setSRConnectionState("SIGNED")
          })
          .with([false, true], () => {
            setSRConnectionState("SIGN_ERROR")
          })
          .otherwise(() => {
            /* continue waiting */
          })
      })
      .with("SIGNED", () => {
        login({ signature, address })
        setSRConnectionState("LOGGING_IN")
      })
      .with("LOGGING_IN", () => {
        match([isLoginMutating, loginData, loginError])
          .with([true, P.any, P.any], () => {})
          .with([false, P.nonNullable, P.nullish], ([, data]) => {
            if (data.status === 200) {
              setSRConnectionState("LOGGED_IN")
            } else {
              setSRConnectionState("LOGIN_ERROR")
            }
          })
          .with([false, P.any, P.nonNullable], () => {
            setSRConnectionState("LOGIN_ERROR")
          })
          .exhaustive()
      })
      .with("LOGGED_IN", () => {
        setSRConnectionState("CONNECTED")
      })
      .with("CONNECTED", () => {})
      .with(P.union("SIGN_ERROR", "LOGIN_ERROR"), () => {
        disconnect()
      })
      .run()
  }, [
    srConnectionState,
    isAuthPending,
    authenticationData,
    authenticationError,
    isLoginMutating,
    loginData,
    loginError,
    isVerifyWalletOwnerSuccess,
    isVerifyWalletOwnerError,
  ])

  useEffect(() => {
    if (isConnected) {
      setSRConnectionState("WALLET_CONNECTED")
    }
  }, [isConnected])
}
