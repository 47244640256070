import { Icon } from "@/ui/icon"

const SocialLink = ({
  name,
  href,
  children,
}: {
  name: string
  href: string
  children?: React.ReactNode
}): JSX.Element => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noreferrer noopener"
      title={name}
      className={`
        text-typography-grey-1 duration-300
        dark:invert
        hover:opacity-60
      `}
    >
      {children}
    </a>
  )
}

export const SocialIcons = (): JSX.Element => {
  return (
    <>
      <SocialLink name="Discord" href="https://discord.gg/superrare">
        <Icon name="discord" className="size-5" />
      </SocialLink>
      <SocialLink
        name="Instagram"
        href="https://www.instagram.com/superrare.co/"
      >
        <Icon name="instagram" />
      </SocialLink>
      <SocialLink name="Twitter" href="https://twitter.com/SuperRare">
        𝕏
      </SocialLink>
      <SocialLink
        name="YouTube"
        href="https://www.youtube.com/channel/UCp9loE7UzFpFxtQHNK8TbKg"
      >
        <Icon name="youtube" />
      </SocialLink>
      <SocialLink name="Reddit" href="https://www.reddit.com/r/SuperRare/">
        <Icon name="reddit" />
      </SocialLink>
    </>
  )
}
