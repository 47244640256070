"use client"

import { StackedTabs } from "@/ui/tabsStacked"
import { AnimatePresence, motion } from "framer-motion"
import { usePathname, useRouter } from "next/navigation"

const MobileNav = (): JSX.Element | null => {
  const router = useRouter()
  const tab = usePathname()
  const pagesToDisplay = ["/", "/feed", "/releases", "/explore"]

  return (
    <AnimatePresence mode="wait">
      {pagesToDisplay.includes(tab) && (
        <motion.div
          key="mobile-nav"
          initial={{ opacity: 0, y: 100 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 100 }}
          className={`
            fixed bottom-0 left-0 z-[500] flex w-full items-center justify-center border-t border-sr-border-tertiary
            bg-sr-bg-primary p-3
            dim:border-sr-border-tertiary
            lg:hidden
          `}
        >
          <div className="w-full px-2 pb-safe">
            <StackedTabs.Provider
              defaultTab={tab}
              onChange={(tab) => router.push(tab)}
              className="w-full flex-1"
            >
              <StackedTabs.List className="w-full">
                <StackedTabs.Tab
                  className="w-1/3 tracking-wide"
                  position="first"
                  value="/"
                >
                  Featured
                </StackedTabs.Tab>
                <StackedTabs.Tab
                  addSplitter
                  className="w-1/3 tracking-wide"
                  value="/feed"
                >
                  Feed
                </StackedTabs.Tab>
                <StackedTabs.Tab
                  className="w-1/3 tracking-wide"
                  position="last"
                  value="/explore"
                >
                  Explore
                </StackedTabs.Tab>
              </StackedTabs.List>
            </StackedTabs.Provider>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default MobileNav
