export const parseViemError = (error: any) => {
  if (error) {
    const details = error.match(/Details: (.*)/)
    const firstLine = details ? details[1].split("  ")[0] : error.split("  ")[0]
    return firstLine
  }
  return error
}
export const unsafeErrorCast = (error: unknown): Error => {
  if (error instanceof Error) {
    return error
  }
  throw new Error(`Error is not an instance of Error: ${JSON.stringify(error)}`)
}
