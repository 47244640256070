import { Avatar } from "@/ui/avatar"
import { DisplayName } from "@/ui/DisplayName"
import Link from "next/link"
import { User } from "@/graphql/generated/apollo/graphql"
import { P, match } from "ts-pattern"

export const AvatarWithDisplayName = ({
  avatarSrc,
  avatarAlt,
  profile,
}: {
  avatarSrc: string
  avatarAlt: string
  profile: User | undefined
}) => {
  const avatarComponent = (
    <Avatar className="size-7" src={avatarSrc} alt={avatarAlt} />
  )
  const displayNameComponent = <DisplayName />

  return (
    <div className="flex flex-row items-center gap-x-1.5">
      {match(profile?.username)
        .with(P.string, (u) => (
          <>
            <Link href={`/${u}`}>{avatarComponent}</Link>
            <Link href={`/${u}`}>{displayNameComponent}</Link>
          </>
        ))
        .otherwise(() => (
          <>
            {avatarComponent}
            {displayNameComponent}
          </>
        ))}
    </div>
  )
}
