import {
  SuperRareBazaarAbi,
  getSuperRareBazaar,
} from "@/contracts/superrarebazaar"
import { useTransactionModalStore } from "@/stores/transactionModal"
import { genToastId } from "@/utils/toast"
import { TxToastError } from "@/utils/txUtils"
import toast from "react-hot-toast"
import { useAccount, useWriteContract } from "wagmi"
export const useRemoveAuction = ({
  contractAddress,
  tokenId,
}: {
  contractAddress: `0x${string}`
  tokenId: number
}): {
  removeAuction: (onSubmit?: () => void) => void
  isLoading: boolean
} => {
  const { chain } = useAccount()
  const { setHash, setToastId } = useTransactionModalStore()
  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const removeAuction = async (onSubmit?: () => void): Promise<void> => {
    const randomId = genToastId()
    if (!writeContractAsync) {
      toast.error("Error: writeAsync " + errorContract)
      return
    }
    try {
      toast.loading("Awaiting user confirmation...", { id: randomId })
      const result = await writeContractAsync({
        address: chain?.id
          ? getSuperRareBazaar(chain?.id)
          : (contractAddress as `0x${string}`),
        abi: SuperRareBazaarAbi,
        functionName: "cancelAuction",
        args: [contractAddress, BigInt(tokenId)],
        chainId: chain?.id,
      })
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return { removeAuction, isLoading: isPending }
}
