import * as NavigationMenu from "@radix-ui/react-navigation-menu"

export const NavBarContent = ({
  children,
  className,
  ...props
}: NavigationMenu.NavigationMenuContentProps): JSX.Element => (
  <NavigationMenu.Content
    className={`
      fixed left-0 top-full h-dvh w-screen animate-scaleIn border-t border-sr-border-primary bg-sr-bg-primary
      lg:absolute lg:left-auto lg:right-0 lg:top-[60px] lg:h-auto lg:w-auto lg:min-w-max lg:rounded-md lg:border
      ${className ?? ""}
    `}
    {...props}
  >
    {children}
  </NavigationMenu.Content>
)
