"use client"

import { useEffect } from "react"
import { useThemeStore } from "@/stores/theme"
import { Icon } from "@/ui/icon"
import { cn } from "@/utils/cn"
import { Tooltip } from "@/ui/tooltip"
import { heapTrack } from "@/utils/heap"
import { useProfileStore } from "@/stores/profile"

export default function DarkMode({
  hideTooltip,
  iconClassName,
}: {
  hideTooltip?: boolean
  iconClassName?: string
}): JSX.Element {
  const { mode, setMode, toggleMode, userToggled, setUserToggled } =
    useThemeStore()
  const { isHeapLoaded } = useProfileStore()

  useEffect(() => {
    if (mode === "light") {
      document.documentElement.classList.remove("dark", "dim")
    } else if (mode === "dark") {
      document.documentElement.classList.add("dark")
      document.documentElement.classList.remove("dim")
    } else if (mode === "dim") {
      document.documentElement.classList.add("dark", "dim")
    }
    if (isHeapLoaded)
      heapTrack("theme selected", {
        mode: mode,
      })
  }, [mode])

  useEffect(() => {
    if (!userToggled) {
      const userPrefersDark =
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches

      if (userPrefersDark) {
        setMode("dim")
      }
    }
  }, [mode, userToggled])

  return (
    <>
      <Tooltip
        side="bottom"
        sideOffset={30}
        hidden={hideTooltip}
        content={"Light -> Dim -> Dark"}
      >
        <button
          className="flex items-center gap-x-2"
          onClick={() => {
            toggleMode()
            setUserToggled(true)
          }}
        >
          <Icon
            name="half"
            invertOnDark={mode === "light" || mode === "dark" || mode === "dim"}
            className={cn(
              "h-5 w-5 duration-300",
              {
                "rotate-0": mode === "light",
                "rotate-90": mode === "dim",
                "rotate-180": mode === "dark",
              },
              iconClassName
            )}
          />
        </button>
      </Tooltip>
    </>
  )
}
