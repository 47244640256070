//import { MUX_URL } from "@/constants/environment"
const MUX_URL = "https://stream.mux.com" // TODO: Replace with MUX_URL env var

export type RESOLUTION = "low" | "medium" | "high" | "m3u8"
export const getMuxUrl = (assetId: string, resolution: RESOLUTION): string => {
  if (resolution === "m3u8") {
    return `${MUX_URL}/${assetId}.m3u8`
  }

  return `${MUX_URL}/${assetId}/${resolution}.mp4`
}

export const sanitizeUrl = (iframeUrl: string): string => {
  let url = iframeUrl
  if (iframeUrl.includes("ipfs://")) {
    url = url.replace("ipfs://", "https://ipfs.io/ipfs/")
  }
  return url
}
