"use client"
import { infoAction, useModal } from "@/stores/infoModal"
import { Button } from "@/ui/button"
import { Dialog } from "@/ui/dialog"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { useRouter } from "next/navigation"
import React from "react"

const InfoModal = () => {
  const { isOpen, setIsOpen, type, action, setAction } = useModal()
  const { openConnectModal: showDialog } = useConnectModal()
  const router = useRouter()
  const actionCopy: Record<infoAction, string> = {
    mute: "To “mute” and make the most of SuperRare complete your profile.",
    generic:
      "Complete your profile to follow artists and collectors, favorite artworks, track offers and build your collection.",
    "new-art":
      "Nice artwork! Complete your profile to track offers, follow your favorite artists and collectors and build your collection.",
  }
  const closeModal = () => {
    setIsOpen(false), setAction("generic")
  }
  return (
    <Dialog open={isOpen} onOpenChange={closeModal}>
      <Dialog.Content
        title="Complete Profile"
        size="xs"
        className="overflow-auto"
      >
        {type === "complete-profile" && (
          <>
            <p className="my-4">
              {action ? actionCopy[action] : actionCopy?.generic}
            </p>
            <Button
              className="mt-10 w-full"
              uppercase
              kind="primary"
              onClick={() => {
                setIsOpen(false)
                router.push("/account/edit-profile")
              }}
            >
              Complete profile
            </Button>
          </>
        )}
        {type === "connect-wallet" && (
          <>
            <p>You need to connect your wallet</p>
            <Button
              className="mt-4 w-full"
              uppercase
              kind="primary"
              onClick={showDialog}
            >
              Connect Wallet
            </Button>
          </>
        )}
      </Dialog.Content>
    </Dialog>
  )
}

export default InfoModal
