import { useAccount, useWriteContract } from "wagmi"
import toast from "react-hot-toast"
import { genToastId } from "@/utils/toast"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { getTxValue } from "@/utils/priceUtils"
import { zeroAddress } from "viem"
import { useSetMaxAllowance } from "./useSetMaxAllowance"
import {
  SuperRareBazaarAbi,
  getSuperRareBazaar,
} from "@/contracts/superrarebazaar"
import { TxToastError } from "@/utils/txUtils"
import { useTransactionModalStore } from "@/stores/transactionModal"

export type BidToken = Pick<
  Erc721Token,
  "contract_address" | "token_id" | "erc721_owner"
>

export const useAuctionBid = ({
  token,
  weiPrice,
  currencyAddress,
}: {
  token: BidToken
  marketAddress?: string
  weiPrice: number
  currencyAddress: string
}): {
  bid: (onSubmit?: () => void) => void
  isLoading: boolean
  enoughAllowance: boolean
} => {
  const { address, chain } = useAccount()
  const abi = SuperRareBazaarAbi
  const contract = chain?.id ? getSuperRareBazaar(chain?.id) : "0x"
  const { setHash, setToastId } = useTransactionModalStore()
  const isEth = currencyAddress === zeroAddress
  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()

  const { allowance, isLoadingAllowance, setMaxAllowance } = useSetMaxAllowance(
    contract,
    !!address && !isEth
  )
  const bid = async (onSubmit?: () => void): Promise<void> => {
    const randomId = genToastId()

    try {
      if ((!allowance || Number(allowance) < weiPrice) && !isEth) {
        toast.loading("Approving allowance...", { id: randomId })
        await setMaxAllowance()
        return
      }

      if (!writeContractAsync) {
        toast.error("Error: writeAsync " + errorContract)
        return
      }
      const value = isEth ? getTxValue(weiPrice) : undefined
      toast.loading("Awaiting user confirmation...", { id: randomId })
      const result = await writeContractAsync({
        address: contract as `0x${string}`,
        abi: abi,
        functionName: "bid",
        chainId: chain?.id,
        args: [
          token?.contract_address as `0x${string}`,
          BigInt(token?.token_id),
          currencyAddress as `0x${string}`,
          BigInt(weiPrice),
        ],
        value,
      })
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return {
    bid,
    isLoading: isPending || isLoadingAllowance,
    enoughAllowance: isEth || (!!allowance && allowance >= weiPrice),
  }
}
