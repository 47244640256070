import { useAuctionBid } from "@/hooks/useAuctionBid"
import { useProfileStore } from "@/stores/profile"
import { Currency, getAllCurrencies } from "@/utils/currency"
import { useMemo } from "react"
import Countdown from "react-countdown"
import { formatEther, parseEther } from "viem"
import { useAccount, useBalance } from "wagmi"
import { InputWithCurrency } from "./InputWithCurrency"
import {
  ALLOWANCE_COPY,
  CurrencyDisplay,
  DataRow,
  FixedMobileButton,
  Terms,
  symbolSwap,
} from "./SharedElements"
import { weiToEth } from "@/utils/priceUtils"
import { CurrencyBalanceFormat } from "../connectButton/connectButton"
import { Loader } from "@/ui/loader"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import HR from "@/ui/hr"
import { useModal } from "@/stores/infoModal"

export const BidContent = ({
  token,
  amount,
  setAmount,
  closeModal,
  approvedTerms,
  setApprovedTerms,
}: {
  token: Erc721Token
  amount: string | number
  setAmount: (amount: string | number) => void
  closeModal: () => void
  approvedTerms: boolean
  setApprovedTerms: (approvedTerms: boolean) => void
}): JSX.Element => {
  const { address } = useAccount()
  const currencies = getAllCurrencies()
  const { setType, setIsOpen } = useModal()
  const { profile: account, rareBalance } = useProfileStore()
  const selectedAuctionCurrency = useMemo(
    () =>
      (currencies.find(
        (c) =>
          c.address.toLowerCase() ===
          (token?.nft_auctions?.[0]?.currency_address?.toLowerCase() as unknown as string)
      ) as Currency) || currencies[0],
    [token]
  )
  const { bid, isLoading, enoughAllowance } = useAuctionBid({
    token: token,
    weiPrice: Number(parseEther(String(amount))),
    currencyAddress: selectedAuctionCurrency.address as `0x${string}`,
  })
  const { data: balanceEth } = useBalance({
    address: address as `0x${string}`,
  })
  const ethBalance = balanceEth?.formatted
  const endingTime = token?.nft_auctions?.[0]?.ending_time as string
  const lastBid = token?.nft_auctions?.[0]?.current_nft_auction_bid?.[0]?.amount
    ? BigInt(token?.nft_auctions?.[0]?.current_nft_auction_bid?.[0]?.amount)
    : 0n
  const minBidWei = token?.nft_auctions?.[0]?.minimum_bid
    ? BigInt(token?.nft_auctions?.[0]?.minimum_bid)
    : 0n
  const insuficcientBalance = useMemo(
    () =>
      selectedAuctionCurrency.symbol === "RARE"
        ? Number(amount) >= Number(weiToEth(parseInt(rareBalance.toString())))
        : Number(amount) >= Number(ethBalance || 0),
    [selectedAuctionCurrency.symbol, rareBalance, ethBalance, amount]
  )

  const minimumBid =
    (!!lastBid ? lastBid + lastBid / 10n : false) || minBidWei || 0n
  const weiAmount = Number(parseEther(String(amount)))
  const onBid = () => {
    closeModal()
    if (!account?.username) {
      setType("complete-profile")
      setIsOpen(true)
    }
  }
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:min-h-80
      `}
    >
      {token?.nft_auctions?.[0]?.auction_state !== "PENDING_AUCTION" && (
        <div className="mb-5 flex justify-between bg-sr-bg-tertiary px-3 py-2">
          <p className="text-sr-text-secondary">Auction ends in:</p>
          <Countdown
            date={endingTime}
            renderer={({ days, hours, minutes, seconds }) => (
              <span>{`${days}d ${hours}h ${minutes}m ${seconds}s`}</span>
            )}
          />
        </div>
      )}
      <div>
        <InputWithCurrency
          id="modal-bid-amount"
          amount={amount}
          setAmount={setAmount}
          selectedCurrency={selectedAuctionCurrency}
          setSelectedCurrency={() => ""}
          label="your bid"
          hasSuccess={Number(ethBalance) > weiAmount && weiAmount >= minimumBid}
          hasError={
            amount.toString().length > 0 &&
            Number(amount) !== 0 &&
            weiAmount < minimumBid
              ? {
                  type: "pattern",
                  message: `Bid must be at least: ${formatEther(minimumBid)} ${selectedAuctionCurrency.symbol === "RARE" ? selectedAuctionCurrency.symbol : "Ξ"}`,
                }
              : undefined
          }
          onlyEth
        />
        <div className="mt-6 gap-1">
          <DataRow label="Minimum bid">
            <CurrencyDisplay
              amount={formatEther(BigInt(minimumBid))}
              currency={selectedAuctionCurrency}
              decimals={3}
            />
          </DataRow>
          <DataRow
            label="Your current balance"
            error={
              address &&
              amount.toString().length > 0 &&
              Number(amount) !== 0 &&
              insuficcientBalance
                ? "not enough eth"
                : undefined
            }
          >
            <CurrencyBalanceFormat
              className="text-sm uppercase text-sr-text-primary"
              balance={
                selectedAuctionCurrency.symbol === "RARE"
                  ? weiToEth(parseInt(rareBalance.toString()))
                  : ethBalance || 0
              }
              decimals={3}
              isFormatted
              symbol={symbolSwap(selectedAuctionCurrency.symbol)}
              symbolClass="text-sm"
            />
          </DataRow>

          <DataRow label="Total bid amount  (+3%):" gray>
            <CurrencyDisplay
              amount={amount}
              currency={selectedAuctionCurrency}
              decimals={3}
              withFee
            />
          </DataRow>
        </div>
      </div>
      <div className="mt-10">
        <HR />
        <p className="my-3 text-sm text-sr-text-secondary">
          Bids placed during an auction cannot be withdrawn. By placing a bid
          you indicate that you have read and agree to the{" "}
          <a
            className="underline"
            href="https://help.superrare.com/en/articles/4576436-how-auctions-work-on-superrare"
          >
            Auction Terms of Use
          </a>
        </p>
        <Terms
          approvedTerms={approvedTerms}
          setApprovedTerms={setApprovedTerms}
        />

        <FixedMobileButton
          onClick={() => bid(onBid)}
          disabled={
            isLoading ||
            !approvedTerms ||
            (!!minimumBid && weiAmount < minimumBid) ||
            insuficcientBalance
          }
        >
          {isLoading ? (
            <Loader kind="simple" />
          ) : !enoughAllowance ? (
            ALLOWANCE_COPY
          ) : (
            "SUBMIT BID"
          )}
        </FixedMobileButton>
      </div>
    </div>
  )
}
