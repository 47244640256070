import { currentNetwork } from "@/constants/network"
import {
  SuperRareWhitelistAbi,
  getSuperRareWhitelist,
} from "@/contracts/superrare-whitelist"
import { Address } from "viem"
import { useAccount, useReadContract } from "wagmi"
import { z } from "zod"

export const useIsWhitelisted = (
  address?: Address
): {
  isWhitelisted: boolean | null
  isPending: boolean
} => {
  const account = useAccount()
  const whitelistedAddress = address || account?.address
  const { data, isLoading } = useReadContract(
    whitelistedAddress
      ? {
          address: getSuperRareWhitelist(currentNetwork.id),
          abi: SuperRareWhitelistAbi,
          functionName: "isWhitelisted",
          args: [whitelistedAddress],
        }
      : undefined
  )
  return {
    isWhitelisted: data !== undefined ? z.boolean().parse(data) : null,
    isPending: isLoading,
  }
}
