import { getRareCurrency } from "@/utils/currency"
import { useAccount, useWriteContract } from "wagmi"
import RareToken from "@/abis/RareToken.json"
import { useRareAllowance } from "./useRareAllowance"
import { convertRarestTokenToDecimals } from "@/utils/rarestToken"
import { useEffect } from "react"

export const useSetMaxAllowance = (
  spenderAddress: `0x${string}`,
  enabled: boolean
): {
  isLoadingAllowance: boolean
  setMaxAllowance: () => Promise<void>
  allowance: bigint
} => {
  const { address, chain } = useAccount()
  const {
    allowance,
    isLoading: isLoadingAllowance,
    refetch,
  } = useRareAllowance(enabled, spenderAddress as string, address as string)
  const { writeContractAsync, isPending: loadingAllowance } = useWriteContract()

  useEffect(() => {
    !isLoadingAllowance && refetch()
  }, [enabled])

  const setMaxAllowance = async (): Promise<void> => {
    try {
      const maxUint256 = BigInt(convertRarestTokenToDecimals(100000000))
      await writeContractAsync({
        address: getRareCurrency().address,
        abi: RareToken,
        functionName: "increaseAllowance",
        chainId: chain?.id,
        args: [spenderAddress, maxUint256],
      })
      refetch()
    } catch (error) {}
  }
  return {
    setMaxAllowance,
    isLoadingAllowance: isLoadingAllowance || loadingAllowance,
    allowance,
  }
}
