import { useState } from "react"

export function useApi(apiFunc: () => Promise<Response>): {
  isQueryPending: boolean
  isQuerySuccess: boolean
  isQueryError: boolean
  callApi: () => Promise<Response>
}
export function useApi<T>(apiFunc: (parameters: T) => Promise<Response>): {
  isQueryPending: boolean
  isQuerySuccess: boolean
  isQueryError: boolean
  callApi: (parameters: T) => Promise<Response>
}
export function useApi<T>(apiFunc: (parameters?: T) => Promise<Response>): {
  isQueryPending: boolean
  isQuerySuccess: boolean
  isQueryError: boolean
  callApi: (parameters?: T) => Promise<Response>
} {
  const [isQueryPending, setIsQueryPending] = useState<boolean>(false)
  const [isQuerySuccess, setIsQuerySuccess] = useState<boolean>(false)
  const [isQueryError, setIsQueryError] = useState<boolean>(false)

  async function callApi(): Promise<Response>
  async function callApi<U extends T>(params: U): Promise<Response>
  async function callApi<U extends T>(params?: U): Promise<Response> {
    setIsQueryPending(true)
    setIsQuerySuccess(false)
    setIsQueryError(false)
    let response: Response
    if (params !== undefined) {
      response = await apiFunc(params)
    } else {
      response = await apiFunc()
    }

    setIsQueryPending(false)
    if (response.status !== 200) {
      setIsQueryError(true)
      setIsQuerySuccess(false)
    }
    if (response.status === 200) {
      setIsQuerySuccess(true)
      setIsQueryError(false)
    }
    return response
  }

  return {
    isQueryPending,
    isQuerySuccess,
    isQueryError,
    callApi,
  }
}
