import { Icon } from "@/ui/icon"
import { Input } from "@/ui/input"
import { Popover } from "@/ui/popover"
import { cn } from "@/utils/cn"
import { ethToDollars } from "@/utils/priceUtils"
import useCurrencyStore from "@/stores/currency"
import { Currency, getAllCurrencies } from "@/utils/currency"
import { FieldError } from "react-hook-form"

export const InputWithCurrency = ({
  amount,
  setAmount,
  id = "modal-set-price-amount",
  label,
  selectedCurrency,
  setSelectedCurrency,
  hasSuccess,
  hasError,
  onlyEth,
}: {
  amount: string | number
  setAmount: (amount: string | number | "") => void
  id?: string
  label?: string
  selectedCurrency: Currency
  setSelectedCurrency: (currency: Currency["symbol"]) => void
  hasSuccess?: boolean
  onlyEth?: boolean
  hasError?: FieldError | undefined
}): JSX.Element => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const isRare = selectedCurrency.symbol === "RARE"
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (/[^\d,.]/.test(e.target.value)) return
    setAmount(e.target.value.replace(",", "."))
  }

  return (
    <div>
      <label
        htmlFor={id}
        className="mb-1 text-sm uppercase tracking-wide text-typography-grey-1"
      >
        {label}
      </label>
      <div className="relative mt-1 flex w-full">
        <Input
          id="modal-set-price-amount"
          className={cn(
            `
              h-10 w-full rounded rounded-r-none border border-sr-border-secondary bg-sr-bg-primary pl-2
              text-sr-text-tertiary
              focus:text-sr-text-primary
            `,
            onlyEth && "rounded"
          )}
          value={amount}
          onChange={handleChange}
          fullWidth
          hasSuccess={hasSuccess}
          hasError={hasError}
          suffix={onlyEth ? selectedCurrency.symbol : undefined}
        />

        {!onlyEth && (
          <Popover
            align="end"
            // className="z-10 border border-black bg-white p-3 dark:border-white dark:bg-black"
          >
            <Popover.Trigger>
              <div
                className={`
                  flex h-10 w-24 justify-between rounded rounded-l-none border border-l-0 border-sr-border-secondary p-2
                `}
              >
                <p>{isRare ? "RARE" : "ETH"}</p>
                {!onlyEth && <Icon name="arrowDown" className="my-auto" />}
              </div>
            </Popover.Trigger>
            <Popover.Content style={{ zIndex: 9999 }}>
              <ul className="flex w-20 flex-col gap-2">
                {getAllCurrencies().map((currency) => (
                  <li key={currency.symbol}>
                    <button
                      onClick={() => {
                        setSelectedCurrency(currency.symbol)
                      }}
                    >
                      {currency.symbol}
                    </button>
                  </li>
                ))}
              </ul>
            </Popover.Content>
          </Popover>
        )}
      </div>
      <div className="pt-1 text-xs text-sr-text-tertiary">
        $
        {ethToDollars(
          amount || 0,
          selectedCurrency.symbol === "ETH" ? ethPrice : rarePrice
        )}
      </div>
    </div>
  )
}
