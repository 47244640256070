import { mainnet, sepolia } from "viem/chains"

export const chains = [
  {
    chainId: mainnet.id,
    slug: "eth",
  },
  {
    chainId: sepolia.id,
    slug: "sep-eth",
    testnet: true,
  },
] as const

export const chainIdToSlug = (chainId: number): string | undefined => {
  return chains.find((chain) => chain.chainId === chainId)?.slug
}

export const slugToChainId = (slug: string): number | undefined => {
  return chains.find((chain) => chain.slug === slug)?.chainId
}
