import { mainnet, sepolia } from "viem/chains"
import { CHAIN_ID } from "./environment"
import { createPublicClient, http } from "viem"

export const viemClient = createPublicClient({
  chain: CHAIN_ID === mainnet.id ? mainnet : sepolia,
  transport: http(),
})

export const currentNetwork = CHAIN_ID === mainnet.id ? mainnet : sepolia
