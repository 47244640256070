import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { useAcceptOffer } from "@/hooks/useAcceptOffer"
import { AvatarProfile } from "@/ui/avatarProfile"
import { weiToEth } from "@/utils/priceUtils"
import { FixedMobileButton, SplitsSection } from "./SharedElements"
import { Loader } from "@/ui/loader"
import { Splits } from "./TransactionModal"
import HR from "@/ui/hr"
import { useMemo } from "react"
import { checkIsV1Token } from "@/utils/getTokenAbi"
import { getAddress, zeroAddress } from "viem"
import { useAccount } from "wagmi"

export const AcceptOfferContent = ({
  token,
  closeModal,
  splits,
  setShowSplits,
  showSplits,
  isWaiting,
}: {
  token: Erc721Token
  closeModal?: () => void
  splits: Splits[]
  showSplits: boolean
  setShowSplits: (showSplits: boolean) => void
  isWaiting: boolean
}): JSX.Element => {
  const submitSplits = useMemo(
    () => ({
      splitAddresses: splits.map((split) => split.address as `0x${string}`),
      splitRatios: splits.map((split) => split.ratio),
      totalRatios: splits.reduce((acc, split) => acc + split.ratio, 0),
    }),
    [splits]
  )
  const { chainId } = useAccount()
  const submitToken = useMemo(
    () => ({
      contract_address: token?.contract_address,
      token_id: token?.token_id,
      current_offer: [
        {
          currency_address:
            token?.current_offer?.[0]?.currency_address || zeroAddress,
          amount: token?.current_offer?.[0]?.amount || 0n,
        },
      ],
    }),
    [token]
  )
  const { acceptOffer, isLoading, isApproved } = useAcceptOffer({
    token: submitToken,
    submitSplits: submitSplits,
    isWaiting,
  })
  const bidder = token?.current_offer?.[0]?.bidder
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div className="flex flex-col gap-5">
        <div>
          <p className="text-sm text-sr-text-secondary">Current offer</p>
          <p>
            {weiToEth(token?.current_offer?.[0]?.amount || 0) || 0}{" "}
            {token?.current_offer?.[0]?.currency?.type}
          </p>
        </div>
        {bidder && (
          <span className="mr-1 inline whitespace-nowrap text-sm text-sr-text-tertiary">
            Offer from{" "}
            <span className="text-sr-text-primary">
              @
              <AvatarProfile
                user={bidder}
                size="sm"
                hideAvatar
                className="inline"
              />
            </span>
          </span>
        )}
      </div>
      {!checkIsV1Token(getAddress(token.contract_address), chainId || 1) && (
        <SplitsSection
          splits={splits}
          setShowSplitConfig={setShowSplits}
          className="mt-6"
        />
      )}
      <HR />
      <div>
        {!showSplits && (
          <FixedMobileButton
            onClick={() => acceptOffer(closeModal)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader kind="simple" />
            ) : !isApproved ? (
              "APPROVE CONTRACT"
            ) : (
              "ACCEPT OFFER"
            )}
          </FixedMobileButton>
        )}
      </div>
    </div>
  )
}
