import { Currency } from "@/utils/currency"
import { FixedMobileButton, SplitsSection } from "./SharedElements"
import { Loader } from "@/ui/loader"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { SaleToken, useSetSalePrice } from "@/hooks/useSetSalePrice"
import { getAddress, parseEther } from "viem"
import { InputWithCurrency } from "./InputWithCurrency"
import { Splits } from "./TransactionModal"
import { useMemo } from "react"
import { checkIsV1Token } from "@/utils/getTokenAbi"
import { useAccount } from "wagmi"

export const SetPriceContent = ({
  amount,
  setAmount,
  closeModal,
  selectedCurrency,
  setSelectedCurrency,
  token,
  splits,
  showSplits,
  setShowSplits,
}: {
  amount: string | number
  setAmount: (amount: string | number) => void
  closeModal: () => void
  selectedCurrency: Currency
  setSelectedCurrency: (currency: Currency["symbol"]) => void
  token: Erc721Token
  splits: Splits[]
  showSplits: boolean
  setShowSplits: (showSplits: boolean) => void
}): JSX.Element => {
  const { chainId } = useAccount()
  const submitSplits = useMemo(
    () => ({
      splitAddresses: splits.map((split) => split.address as `0x${string}`),
      splitRatios: splits.map((split) => split.ratio),
      totalRatios: splits.reduce((acc, split) => acc + split.ratio, 0),
    }),
    [splits]
  )
  const { setSalePrice, isApproved, isLoading } = useSetSalePrice({
    weiPrice: Number(parseEther(String(amount))),
    currencyAddress: selectedCurrency.address as `0x${string}`,
    token: token as SaleToken,
    submitSplits: submitSplits,
  })

  const isRare = selectedCurrency.symbol === "RARE"
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        <InputWithCurrency
          amount={amount}
          setAmount={setAmount}
          label="list price"
          id="modal-set-price-amount"
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
        />
        {/* <div className="mt-6 gap-1">
          <DataRow label="+ 3% fee:">
            <CurrencyDisplay
              amount={ethToWei(0.03)}
              currency={selectedCurrency}
            />
          </DataRow>

          <DataRow label="List price displayed to collectors:">
            <CurrencyDisplay
              amount={amount}
              currency={selectedCurrency}
              decimals={3}
              withFee
            />
          </DataRow>
        </div> */}
      </div>
      <>
        {!checkIsV1Token(getAddress(token.contract_address), chainId || 1) && (
          <SplitsSection
            splits={splits}
            setShowSplitConfig={setShowSplits}
            className="mt-6"
          />
        )}
      </>
      <div className="flex h-72 flex-col justify-between">
        <hr className="my-8" />
        {!isRare && (
          <>
            <p className="mt-3">
              You can also list your artwork for sale in $RARE
            </p>
            <p className="mb-12 text-sr-text-secondary">
              Switch the currency to RARE to join the growing community of RARE
              holders. Stake on fellow artists & collectors, earn community
              rewards, and vote on protocol updates.{" "}
              <a className="underline" href="https://rare.xyz/">
                Learn more
              </a>
            </p>
          </>
        )}
        {!showSplits && (
          <FixedMobileButton
            onClick={() => setSalePrice(closeModal)}
            disabled={isLoading}
          >
            {isLoading ? (
              <Loader kind="simple" />
            ) : isApproved ? (
              token?.current_sale_price?.[0]?.amount ? (
                "UPDATE PRICE"
              ) : (
                "SET PRICE"
              )
            ) : (
              "APPROVE"
            )}
          </FixedMobileButton>
        )}
      </div>
    </div>
  )
}
