import { cn } from "@/utils/cn"
import useCurrencyStore from "@/stores/currency"
import { EthUsdCurrency } from "@/utils/priceUtils"
import { RareUsdCurrency } from "@/utils/priceUtils"
import { Currency } from "@/utils/currency"
import { useAccount } from "wagmi"
import { Button } from "@/ui/button"
import { Checkbox } from "@/ui/checkbox"
import { useConnectModal } from "@rainbow-me/rainbowkit"
import { useTransactionModalStore } from "@/stores/transactionModal"
import { Splits } from "./TransactionModal"
import { EthformatAddress } from "@/ui/ethaddress"
import { Icon } from "@/ui/icon"
import { parseEther } from "viem"

export const DataRow = ({
  label,
  children,
  gray,
  error,
}: {
  label: string
  children: React.ReactNode
  gray?: boolean
  error?: string
}): JSX.Element => {
  return (
    <div
      className={cn(
        "flex justify-between gap-1 px-2 py-1 text-sm",
        gray && "bg-sr-bg-tertiary"
      )}
    >
      <p className="text-sr-text-secondary">{label}</p>
      <span
        className={`
          text-end
          md:flex md:gap-1
        `}
      >
        {children}
        {!!error && <p className="text-error">{error}</p>}
      </span>
    </div>
  )
}

export const CurrencyDisplay = ({
  amount,
  currency,
  decimals,
  withFee,
}: {
  amount: number | string
  currency: Currency
  decimals?: number
  withFee?: boolean
}): JSX.Element => {
  const { ethPrice, rarePrice } = useCurrencyStore()
  const price = parseEther(amount.toString()) || 0n
  return (
    <p className="text-sr-text-primary">
      {currency.symbol === "ETH"
        ? `${EthUsdCurrency(price, ethPrice, undefined, decimals, withFee)}`
        : `${RareUsdCurrency(price, rarePrice, undefined, decimals, withFee)}`}
    </p>
  )
}

export const ALLOWANCE_COPY = "Approve RARE"
export const balanceMessage = (isRare: boolean): string =>
  `Not enough ${isRare ? "RARE" : "ETH"}`
export const symbolSwap = (symbol: string): string =>
  symbol === "ETH" ? " Ξ" : symbol
export const FixedMobileButton = ({
  children,
  onClick,
  disabled,
}: {
  children: React.ReactNode
  onClick: () => void
  disabled?: boolean
}): JSX.Element => {
  const { address } = useAccount()
  const { openConnectModal } = useConnectModal()
  const { setIsOpen } = useTransactionModalStore()
  const connectUser = () => {
    setIsOpen(false)
    openConnectModal?.()
  }
  return (
    <div
      className={`
        fixed bottom-0 left-0 w-full border-t border-sr-border-tertiary bg-sr-bg-primary p-3
        dim:border-sr-border-tertiary
        md:static md:w-full md:p-0
      `}
    >
      <Button
        disabled={!!address && disabled}
        kind="primary"
        onClick={!!address ? onClick : connectUser}
        className="w-full"
      >
        {address ? children : "CONNECT WALLET"}
      </Button>
    </div>
  )
}

export const Terms = ({
  approvedTerms,
  setApprovedTerms,
}: {
  approvedTerms: boolean
  setApprovedTerms: (approvedTerms: boolean) => void
}): JSX.Element => {
  return (
    <div className="my-4 text-sm">
      <Checkbox
        id="accept"
        defaultChecked={approvedTerms}
        onCheckedChange={setApprovedTerms}
      >
        I agree to the SuperRare.com{" "}
        <a
          href="https://campaigns.superrare.com/terms"
          target="_blank"
          className={`
            text-black underline
            dark:text-white
          `}
        >
          Terms of Service
        </a>{" "}
        and{" "}
        <a
          href="https://campaigns.superrare.com/privacy"
          target="_blank"
          className={`
            text-black underline
            dark:text-white
          `}
        >
          Privacy Notice
        </a>
      </Checkbox>
    </div>
  )
}

export const SplitsSection = ({
  splits,
  setShowSplitConfig,
  className,
}: {
  splits: Splits[]
  setShowSplitConfig: (showSplitConfig: boolean) => void
  className?: string
}): JSX.Element => {
  return (
    <div className={cn("flex flex-col gap-3", className)}>
      <p className="flex text-sm">
        Split a % of this artwork’s sale price with other wallet addresses.
      </p>
      <div className="flex flex-wrap gap-2">
        {splits.map((split, index) => (
          <Button
            key={`${index}-${split.ratio}`}
            kind="secondary"
            className="whitespace-nowrap text-sm"
            onClick={() => setShowSplitConfig(true)}
          >
            {EthformatAddress(split.address)}
            <span className="text-sr-text-secondary">|</span>
            {split.ratio}%
          </Button>
        ))}
        {splits.length < 4 && (
          <Button kind="secondary" onClick={() => setShowSplitConfig(true)}>
            <Icon className="h-4 w-4" invertOnDark name="plus" />
            {splits.length === 0 ? "SPLITS" : ""}
          </Button>
        )}
      </div>
    </div>
  )
}
