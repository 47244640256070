import { usePathname } from "next/navigation"
import Link from "next/link"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"
import { ComponentProps } from "react"

export const NavBarLink = ({
  className,
  href,
  ...props
}: NavigationMenu.NavigationMenuLinkProps & ComponentProps<typeof Link>) => {
  const pathname = usePathname()
  const isActive = href === pathname

  return (
    <NavigationMenu.Link
      asChild
      active={isActive}
      className={`
        relative flex items-center rounded-md p-2 text-base
        hover:bg-sr-bg-tertiary hover:dark:bg-night-grey-4
        lg:text-sm lg:uppercase lg:hover:bg-transparent lg:dark:hover:bg-transparent
        ${className ?? ""}
      `}
    >
      <Link href={href} {...props} />
    </NavigationMenu.Link>
  )
}
