import Link from "next/link"

type CompleteProfileCtaProps = {
  onClick?: () => void
  className?: string
}

export const CompleteProfileCta = ({
  onClick,
  className,
}: CompleteProfileCtaProps) => {
  return (
    <div className={className}>
      <p className="text-sm sr-text-secondary mb-1.5">
        Follow artists, favorite artworks, track offers and build your
        collection.
      </p>
      <Link
        className={`
          flex h-[28px] w-full items-center justify-center gap-x-3 rounded-md border border-sr-border-primary
          bg-sr-bg-primary px-5 text-sm uppercase duration-300
          active:text-sr-text-secondary
          disabled:border-sr-border-tertiary disabled:bg-sr-bg-tertiary
          hover:border-sr-border-secondary
        `}
        href="/account/edit-profile"
        onClick={onClick}
      >
        Complete Profile
      </Link>
    </div>
  )
}
