import { Address } from "viem"
import { useApi } from "./useApi"

export type LoginParams = {
  signature: string | null
  address: Address | null
}

const postLogout = async () => {
  const response = await fetch("/api/next/auth/logout", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
  })

  return response
}

export const useLogout = () => {
  const { isQueryPending, isQuerySuccess, isQueryError, callApi } =
    useApi(postLogout)

  return { isQueryPending, isQuerySuccess, isQueryError, logout: callApi }
}
