import { BidToken } from "./useAuctionBid"
import { TxToastError } from "@/utils/txUtils"
import toast from "react-hot-toast"
import { useAccount, useWriteContract } from "wagmi"
import { useIsContractApproved } from "./useIsApproved"
import { genToastId } from "@/utils/toast"
import {
  SuperRareBazaarAbi,
  getSuperRareBazaar,
} from "@/contracts/superrarebazaar"
import { useTransactionModalStore } from "@/stores/transactionModal"

export const useSettleAuction = ({
  token,
}: {
  token: BidToken
}): {
  settleAuction: (onSubmit?: () => void) => Promise<void>
  isPending: boolean
  isApproved: boolean
} => {
  const { address, chain } = useAccount()
  const abi = SuperRareBazaarAbi
  const bazaarContract = chain?.id ? getSuperRareBazaar(chain?.id) : null
  const { writeContractAsync, isPending } = useWriteContract()
  const { setHash, setToastId } = useTransactionModalStore()
  const {
    isApproved,
    approve,
    isLoading: isLoadingIsApproved,
  } = useIsContractApproved({
    ownerAddress: address as string,
    contractAddress: token?.contract_address as string,
  })
  const randomId = genToastId()
  const settleAuction = async (onSubmit?: () => void): Promise<void> => {
    if (isPending || isLoadingIsApproved) return
    if (!isApproved) {
      await approve()
      return
    }
    try {
      toast.loading("Awaiting user confirmation...", { id: randomId })
      const result = await writeContractAsync({
        address: bazaarContract as `0x${string}`,
        abi: abi,
        functionName: "settleAuction",
        chainId: chain?.id,
        args: [
          token?.contract_address as `0x${string}`,
          BigInt(token?.token_id),
        ],
      })
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return { settleAuction, isPending, isApproved }
}
