import { useTransactionModalStore } from "@/stores/transactionModal"
import { getTokenAbi } from "@/utils/getTokenAbi"
import { genToastId } from "@/utils/toast"
import { TxToastError } from "@/utils/txUtils"
import toast from "react-hot-toast"
import { useAccount, useWriteContract } from "wagmi"

// const buildArgsForTransfer = ({
//   fromAddress,
//   toAddress,
//   tokenId,
//   contractAddress,
//   chainId,
// }: {
//   contractAddress?: string
//   chainId?: number
//   toAddress?: string
//   fromAddress?: string
//   tokenId?: number
// }): {
//   contractAddress: `0x${string}`
//   abi: any[]
//   functionName: string
//   args: (string | number)[]
//   chainId: number
// } | null => {
//   if (!contractAddress || !chainId || !toAddress || !fromAddress || !tokenId) {
//     return null
//   }
//   const abiDetails = getTokenAbi(contractAddress, chainId)
//   const { type, abi } = abiDetails
//   switch (type) {
//     case "SuperRareV1": {
//       return {
//         contractAddress: getAddress(contractAddress),
//         abi,
//         functionName: "transfer",
//         args: [toAddress, tokenId],
//         chainId,
//       }
//     }
//     case "ERC721": {
//       return {
//         contractAddress: getAddress(contractAddress),
//         abi,
//         functionName: "transferFrom",
//         args: [fromAddress, toAddress, tokenId],
//         chainId,
//       }
//     }
//   }
// }

export const useTransfer = ({
  toAddress,
  fromAddress,
  tokenId,
  contractAddress,
  chainId,
}: {
  toAddress?: `0x${string}`
  fromAddress?: `0x${string}`
  tokenId?: number
  contractAddress: `0x${string}`
  chainId?: number
}): {
  transfer: (onSubmit?: () => void) => void
  isPending: boolean
} => {
  const { chain: walletChain } = useAccount()
  const { setHash, setToastId } = useTransactionModalStore()
  // const transferArgs = buildArgsForTransfer({
  //   fromAddress,
  //   toAddress,
  //   tokenId,
  //   contractAddress,
  //   chainId: chainId || walletChain?.id || 1,
  // })
  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const transfer = async (onSubmit?: () => void): Promise<void> => {
    if (!toAddress || !fromAddress || !tokenId) {
      toast.error("Error: missing args ")
      return
    }
    const randomId = genToastId()
    if (!writeContractAsync) {
      toast.error("Error: writeContractAsync " + errorContract + "Args ")
      return
    }
    try {
      toast.loading("Awaiting user confirmation...", { id: randomId })
      // const result = await writeContractAsync({
      //   abi: transferArgs?.abi,
      //   address: transferArgs?.contractAddress,
      //   functionName: transferArgs?.functionName,
      //   args: transferArgs?.args,
      // })
      const runTx = async () => {
        const abiDetails = getTokenAbi(
          contractAddress,
          chainId || walletChain?.id || 1
        )
        switch (abiDetails._type) {
          case "SuperRareV1": {
            const result = await writeContractAsync({
              address: contractAddress,
              abi: abiDetails?.abi,
              functionName: "transfer",
              args: [toAddress, BigInt(tokenId)],
            })
            return result
          }
          case "ERC721": {
            const result = await writeContractAsync({
              address: contractAddress,
              abi: abiDetails?.abi,
              functionName: "transferFrom",
              args: [fromAddress, toAddress, BigInt(tokenId)],
            })
            return result
          }
        }
      }
      const result = await runTx()
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }

  return { transfer, isPending }
}
