"use client"
import { Config } from "wagmi"
import { readContract } from "wagmi/actions"
import { getDefaultMarketplaceTokenAbi } from "./getTokenAbi"
import { zeroAddress } from "viem"
import { z } from "zod"
import { Address } from "./common.types"

export const getTokenPrice = async ({
  address,
  tokenId,
  chainId = 1,
  config,
}: {
  address: Address
  tokenId: bigint
  chainId?: number
  config: Config
}): Promise<bigint> => {
  const abiDetails = getDefaultMarketplaceTokenAbi(address, chainId)
  if (abiDetails._type === "SuperRareV1") {
    const result = await readContract(config, {
      address: abiDetails?.contract,
      abi: abiDetails.abi,
      functionName: "salePriceOfToken",
      args: [tokenId],
    })
    return z.coerce.bigint().parse(result)
  }
  const result = await readContract(config, {
    address: abiDetails?.contract,
    abi: abiDetails.abi,
    functionName: "getSalePrice",
    args: [address, tokenId, zeroAddress],
  })
  return z.coerce.bigint().parse(result[2])
}
