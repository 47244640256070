import { getChainClients } from "@/utils/chainClients"
import { useEffect, useState } from "react"
import { useAccount, useChainId } from "wagmi"

export const useWaitForTxConfirmation = ({
  hash,
  onWait,
  onConfirmation,
  onError,
}: {
  hash: `0x${string}` | undefined
  onWait: () => void
  onConfirmation: () => void
  onError: (error: any) => void
}): { isWaiting: boolean } => {
  const chainId = useChainId()
  const client = chainId ? getChainClients(chainId).viem : null
  const { chain } = useAccount()
  const [isWaiting, setIsWaiting] = useState(false)
  useEffect(() => {
    if (!hash || !chain) {
      return
    }
    setIsWaiting(true)
    let tries = 0
    const maxTries = 6
    const interval = setInterval(async () => {
      onWait()
      tries++
      if (tries > maxTries) {
        clearInterval(interval)
        onError(new Error("Transaction confirmation limit exceeded"))
        setIsWaiting(false)
        return
      }
      try {
        const receipt = await client?.waitForTransactionReceipt({
          hash,
        })
        if (receipt?.status === "success") {
          onConfirmation()
          clearInterval(interval)
          setIsWaiting(false)
        } else if (receipt?.status === "reverted") {
          onError(new Error("Transaction failed"))
          clearInterval(interval)
        }
      } catch (error: any) {
        if (
          (error as { name: string })?.name == "TransactionReceiptNotFoundError"
        ) {
          return
        }
        onError(error)
        clearInterval(interval)
      }
    }, 6000)
    return () => clearInterval(interval)
  }, [hash, chain])
  return { isWaiting }
}

export default useWaitForTxConfirmation
