export const TxToastError = (
  error: Error,
  id: string
): { message: JSX.Element; options: { id: string; duration: number } } => {
  if (error?.message.includes("rejected")) {
    return {
      message: <p>Transaction rejected</p>,
      options: { id, duration: 3000 },
    }
  }
  if (error.message.includes("reverted")) {
    // We console.error here to make sure the `reverted` details are visibile in the console
    // eslint-disable-next-line no-console
    console.error(error.message)
  }
  return {
    message: (
      <div>
        <p>
          Txn failed, please try again. If the problem persists, please contact
          our support.{" "}
          <a
            href="https://help.superrare.com/en/articles/9264444"
            target="_blank"
            className="underline ml-1"
          >
            Learn more
          </a>
        </p>
      </div>
    ),
    options: { id, duration: 10000 },
  }
}
