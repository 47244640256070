import BigNumber from "bignumber.js"

export function convertRarestTokenBalance(value: string, decimals = 2): string {
  return new BigNumber(value)
    .div("1000000000000000000")
    .toFixed(decimals)
    .toString()
}

export function convertRarestTokenToDecimals(value: string | number): string {
  return new BigNumber(value).times("1000000000000000000").toFixed()
}
