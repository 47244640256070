import { Currency } from "@/utils/currency"
import { useMemo, useState } from "react"
import { parseEther } from "viem"
import { InputWithCurrency } from "./InputWithCurrency"
import { FixedMobileButton, SplitsSection } from "./SharedElements"
import { Loader } from "@/ui/loader"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import HR from "@/ui/hr"
import { AuctionType, useConfigureAuction } from "@/hooks/useConfigureAuction"
import { formatDateTime } from "@/utils/datetime"
import moment from "moment"
import { Tabs } from "@/ui/tabs"
import { DatePicker } from "@/ui/datePicker"
import { TimePicker } from "@/ui/timePicker"
import { Splits } from "./TransactionModal"
import { Icon } from "@/ui/icon"

export const AuctionContent = ({
  amount,
  setAmount,
  selectedCurrency,
  setSelectedCurrency,
  token,
  closeModal,
  splits,
  showSplits,
  setShowSplits,
}: {
  amount: string | number
  setAmount: (amount: string | number) => void
  selectedCurrency: Currency
  setSelectedCurrency: (currency: Currency["symbol"]) => void
  closeModal?: () => void
  token: Erc721Token
  splits: Splits[]
  showSplits: boolean
  setShowSplits: (showSplits: boolean) => void
}): JSX.Element => {
  const [type, setType] = useState<AuctionType>("COLDIE_AUCTION")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [startTime, setStartTime] = useState("")
  const [endTime, setEndTime] = useState("")
  const isReserve = type === "COLDIE_AUCTION"
  const submitSplits = useMemo(
    () => ({
      splitAddresses: splits.map((split) => split.address as `0x${string}`),
      splitRatios: splits.map((split) => split.ratio),
      totalRatios: splits.reduce((acc, split) => acc + split.ratio, 0),
    }),
    [splits]
  )
  const { isApproved, configureAuction, isLoading } = useConfigureAuction({
    token: token,
    startingWeiPrice: amount ? Number(parseEther(String(amount))) : 0,
    auctionType: type,
    currencyAddress: selectedCurrency.address as `0x${string}`,
    startTime: formatDateTime(startDate, startTime),
    endingTime: formatDateTime(endDate, endTime),
    submitSplits: submitSplits,
  })
  const disabledEndingDays = useMemo(
    () => (date: Date) => {
      return startDate
        ? moment(date).isBefore(moment(startDate, "MMMM Do, YYYY"))
        : moment(date).isBefore(moment(new Date()))
    },
    [startDate]
  )
  const offset = new Date().getTimezoneOffset()
  const offsetHours = Math.abs(offset / 60)
  const sign = offset > 0 ? "-" : "+"
  const disabledStartDays = useMemo(
    () => (date: Date) => {
      const yesterday = moment(new Date()).subtract(1, "days")
      //only able to pick same day if i choose the previous day
      return moment(date).isBefore(yesterday)
    },
    []
  )
  const handleEndDateChange = (date: string): void => {
    if (moment(date).isBefore(moment(startDate))) return
    setEndDate(date)
  }
  return (
    <div className="flex h-full flex-col justify-between">
      <Tabs.Provider
        className="my-8"
        defaultTab="COLDIE_AUCTION"
        onChange={(tab) => setType(tab as AuctionType)}
      >
        <Tabs.List>
          <Tabs.Tab value="COLDIE_AUCTION">Reserve</Tabs.Tab>
          <Tabs.Tab value="SCHEDULED_AUCTION">Scheduled</Tabs.Tab>
        </Tabs.List>
      </Tabs.Provider>
      {type === "COLDIE_AUCTION" ? (
        <div>
          <InputWithCurrency
            amount={amount}
            setAmount={setAmount}
            label="reserve price"
            id="modal-auction-reserve-amount"
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            onlyEth
          />
        </div>
      ) : (
        <div>
          <p className="flex pb-6 text-sm text-sr-text-secondary">
            <Icon name="info" className="mr-2" invertOnDark />
            Your timezone:{" "}
            {Intl.DateTimeFormat()
              .resolvedOptions()
              .timeZone.replace(/_/g, " ")}{" "}
            (UTC {sign}
            {offsetHours})
          </p>
          <div className="flex gap-4 z-99999 relative">
            <DatePicker
              label="start date"
              onChange={(date) => setStartDate(date)}
              value={startDate}
              placeholder="-"
              disabledDays={disabledStartDays}
            />
            <TimePicker
              onChange={(time) => setStartTime(time)}
              value={startTime}
              fromCurrentTime={
                startDate === moment(new Date()).format("MMMM Do, YYYY")
                  ? true
                  : false
              }
              placeholder="-"
              label="start time"
              unwrapped
            />
          </div>
          <div className="my-8 flex gap-4">
            <DatePicker
              label="end date"
              onChange={(date) => handleEndDateChange(date)}
              value={endDate}
              placeholder="-"
              disabledDays={disabledEndingDays}
            />
            <TimePicker
              onChange={(time) => setEndTime(time)}
              value={endTime}
              placeholder="-"
              label="end time"
              unwrapped
            />
          </div>
          <InputWithCurrency
            amount={amount}
            setAmount={setAmount}
            label="starting  price"
            id="modal-auction-scheduled-amount"
            selectedCurrency={selectedCurrency}
            setSelectedCurrency={setSelectedCurrency}
            onlyEth
          />
        </div>
      )}
      <SplitsSection
        splits={splits}
        setShowSplitConfig={setShowSplits}
        className="mt-6"
      />
      <div className="mt-10">
        <HR />
        <p className="my-3 text-sm text-sr-text-secondary">
          {isReserve
            ? `The artwork will immediately be available for bidding starting at the reserve price,
            without a time limit. When the reserve is met, a 24-hour timed auction
            will begin, with 15 minute extensions in the final 15 minutes.`
            : `Timer will count down to the start of the auction, at which point bidding will
            become available and the timer will count down to the auction end. If you select a
            starting price, bids must at least meet it. Learn how auctions work`}{" "}
          <a
            className="underline"
            href="https://help.superrare.com/en/articles/4576436-how-auctions-work-on-superrare#reserve-auctions"
          >
            Learn how auctions work
          </a>
        </p>
        {!showSplits && (
          <FixedMobileButton
            onClick={() => configureAuction(closeModal)}
            disabled={
              isLoading ||
              (isReserve && (Number(amount) <= 0 || amount === "")) ||
              (!isReserve &&
                (startDate === "" ||
                  endDate === "" ||
                  endTime === "" ||
                  startTime === ""))
            }
          >
            {isLoading ? (
              <Loader kind="simple" />
            ) : !isApproved ? (
              "APPROVE CONTRACT"
            ) : isReserve ? (
              "SET RESERVE"
            ) : (
              "SCHEDULE AUCTION"
            )}
          </FixedMobileButton>
        )}
      </div>
    </div>
  )
}
