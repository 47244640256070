"use client"

import DarkMode from "./darkMode"
import { AnimatePresence, motion } from "framer-motion"
import { useAccount, useEnsAvatar, useEnsName } from "wagmi"
import { Icon } from "@/ui/icon"
import useEthPriceUpdater from "@/hooks/useEthPriceUpdater"
import { useProfileStore } from "@/stores/profile"
import { useSRConnect } from "@/hooks/useSRConnect"
import { SubNavCuration } from "./SubNavCuration"
import { SubNavBurger } from "./SubNavBurger"
import * as NavigationMenu from "@radix-ui/react-navigation-menu"
import HR from "@/ui/hr"
import { ChevronDownIcon, Cross1Icon } from "@radix-ui/react-icons"
import { useEffect, useState } from "react"
import { Avatar } from "@/ui/avatar"
import makeBlockie from "ethereum-blockies-base64"
import { normalize } from "path"
import { AvatarWithDisplayName } from "@/components/header/AvatarWithDisplayName"
import { CompleteProfileCta } from "@/components/header/CompleteProfileCta"
import { SubNavUserHeader } from "@/components/header/SubNavUserHeader"
import { useIsWhitelisted } from "@/hooks/useIsWhitelisted"
import Search from "@/components/header/searchAlgolia"
import { NavBarLink } from "@/components/header/NavBarLink"
import { NavBarContent } from "@/components/header/NavBarContent"
import { trpc } from "@/trpc/client"
import { Loader } from "@/ui/loader"
import { ConnectWalletButton } from "@/components/header/ConnectWalletButton"
import { useSRDisconnect } from "@/hooks/useSRDisconnect"
import { SubNavBarLink } from "@/components/header/SubNavBarLink"
import { Address } from "viem"

export default function Navbar(): JSX.Element {
  const { address, status, isConnected } = useAccount()
  useProfileStore()
  useEthPriceUpdater() // update eth price
  useSRConnect()

  const [currentNavItem, setCurrentNavItem] = useState("")
  const { data: maybeEnsName } = useEnsName({ address })
  const { data: maybeEnsAvatar } = useEnsAvatar({
    name: normalize(maybeEnsName || ""),
  })
  const { profile, setProfile } = useProfileStore()
  const { isWhitelisted } = useIsWhitelisted(
    profile?.ethereum_address as Address
  )
  const getProfile = trpc.profile.getByAddresses.useQuery(
    {
      addresses: address ? [address.toLowerCase()] : [],
    },
    {
      enabled: false,
    }
  )
  const { disconnect } = useSRDisconnect()

  useEffect(() => {
    if (currentNavItem !== "") {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "auto"
    }
  }, [currentNavItem])

  useEffect(() => {
    if (address) getProfile.refetch()
  }, [address])

  useEffect(() => {
    const user = getProfile.data?.users.users_by_ethereum_addresses[0]
    setProfile(user || {})
  }, [getProfile.data])

  const resetNav = () => setCurrentNavItem("")
  const handlePointerDownOutside = (event: Event) => {
    if (
      event.target instanceof Element &&
      event.target.closest("button")?.dataset["radixCollectionItem"] ===
        undefined
    ) {
      resetNav()
    }
  }

  return (
    <nav
      className={`
        flex items-center justify-center gap-x-2
        lg:gap-x-6
      `}
    >
      <NavigationMenu.Root
        value={currentNavItem}
        orientation="horizontal"
        className="lg:relative"
      >
        <NavigationMenu.List
          className={`
            flex flex-row items-center gap-x-2
            lg:gap-x-4
          `}
        >
          <NavigationMenu.Item
            value="art"
            className={`
              hidden
              lg:block
            `}
          >
            <NavBarLink href="/explore">Art</NavBarLink>
          </NavigationMenu.Item>
          <NavigationMenu.Item
            value="feed"
            className={`
              hidden
              lg:block
            `}
          >
            <NavBarLink href="/feed">Feed</NavBarLink>
          </NavigationMenu.Item>
          <NavigationMenu.Item
            value="releases"
            className={`
              hidden
              lg:block
            `}
          >
            <NavBarLink href="/releases">Releases</NavBarLink>
          </NavigationMenu.Item>
          <NavigationMenu.Item
            value="curation"
            className={`
              hidden
              lg:relative lg:block
            `}
          >
            <NavigationMenu.Trigger
              className="flex justify-between gap-x-1 p-2 text-sm uppercase"
              onClick={() =>
                setCurrentNavItem((s) => (s === "curation" ? "" : "curation"))
              }
            >
              Curation <ChevronDownIcon className="dropdown-arrow" />
            </NavigationMenu.Trigger>
            <NavBarContent onPointerDownOutside={handlePointerDownOutside}>
              <SubNavCuration className="lg:min-w-max" onClick={resetNav} />
            </NavBarContent>
          </NavigationMenu.Item>

          <NavigationMenu.Item
            value="search"
            className={`
              ${currentNavItem === "hamburger" ? "hidden" : "block"}
              lg:order-first lg:block lg:hidden
            `}
          >
            <NavigationMenu.Trigger
              onClick={() =>
                setCurrentNavItem((s) => (s === "search" ? "" : "search"))
              }
            >
              {currentNavItem === "search" ? (
                <span
                  className={`
                    flex h-9 w-9 items-center justify-center rounded-md bg-sr-bg-tertiary duration-300
                    dark:hover:bg-sr-700
                    hover:bg-sr-400
                  `}
                >
                  <Cross1Icon />
                </span>
              ) : (
                <span
                  className={`
                    relative flex size-9 items-center justify-center rounded-md border border-sr-border-tertiary
                    dark:border-white
                    dim:border-sr-700
                    lg:border-0
                  `}
                >
                  <Icon name="search" invertOnDark />
                </span>
              )}
            </NavigationMenu.Trigger>
            <NavBarContent>
              <Search onClick={resetNav} />
            </NavBarContent>
          </NavigationMenu.Item>

          <NavigationMenu.Item
            value="darkMode"
            className={`
              ${currentNavItem === "hamburger" ? "block" : "hidden"}
              lg:order-1 lg:mr-3 lg:block
            `}
          >
            <NavigationMenu.Trigger asChild>
              <div
                data-name="darkModeTrigger"
                className={`
                  flex h-9 w-9 items-center justify-center rounded-md border border-sr-border-tertiary duration-300
                  dark:hover:bg-sr-700
                  hover:bg-sr-400
                  lg:w-5 lg:border-0 lg:hover:bg-transparent lg:dark:hover:bg-transparent
                `}
              >
                <DarkMode iconClassName="w-4 h-4 lg:size-5" hideTooltip />
              </div>
            </NavigationMenu.Trigger>
          </NavigationMenu.Item>

          <NavigationMenu.Item
            value="hamburger"
            className="lg:relative lg:ml-3"
          >
            <NavigationMenu.Trigger
              onClick={() =>
                setCurrentNavItem((s) => (s === "hamburger" ? "" : "hamburger"))
              }
              className="h-full"
            >
              <>
                {currentNavItem === "hamburger" && (
                  <span
                    className={`
                      flex h-9 w-9 items-center justify-center rounded-md bg-sr-bg-tertiary duration-300
                      dark:hover:bg-sr-700
                      hover:bg-sr-400
                      lg:hidden lg:w-5
                    `}
                  >
                    <Cross1Icon />
                  </span>
                )}
                <span
                  className={`
                    ${currentNavItem === "hamburger" ? "hidden" : `flex`}
                    relative size-9 items-center justify-center rounded-md border border-sr-border-tertiary
                    dark:border-white
                    dim:border-sr-700
                    lg:flex lg:w-5 lg:border-0
                  `}
                >
                  <Icon
                    name="menu"
                    className={`
                      h-4 w-4
                      dark:invert
                      lg:h-5 lg:w-5
                    `}
                  />
                </span>
              </>
            </NavigationMenu.Trigger>
            <NavBarContent
              onPointerDownOutside={(event) => {
                if (
                  event.target instanceof Element &&
                  event.target.closest("div")?.dataset["name"] ===
                    "darkModeTrigger"
                ) {
                  event.stopPropagation()
                  event.preventDefault()
                  return
                }
                handlePointerDownOutside(event)
              }}
            >
              <SubNavBurger onClick={resetNav} />
            </NavBarContent>
          </NavigationMenu.Item>
          {status == "connected" && (
            <>
              <NavigationMenu.Item
                value="user"
                className={`
                  order-last
                  lg:relative
                `}
              >
                <NavigationMenu.Trigger
                  onClick={() =>
                    setCurrentNavItem((s) => (s === "user" ? "" : "user"))
                  }
                >
                  <span
                    className={`
                      ${currentNavItem === "user" ? "hidden" : "flex"}
                      relative size-9 items-center justify-center rounded-md
                      lg:flex lg:w-7
                    `}
                  >
                    {profile && profile.unread_notifications_count > 0 && (
                      <span
                        className={`absolute right-1 top-1 z-10 size-[6px] rounded-full bg-red-500`}
                      ></span>
                    )}
                    <AnimatePresence mode="wait">
                      {getProfile.isLoading ? (
                        <motion.div
                          initial={{ opacity: 0 }}
                          animate={{ opacity: 1 }}
                          exit={{ opacity: 0, y: 15 }}
                          key="loader"
                        >
                          <Loader kind="simple" />
                        </motion.div>
                      ) : (
                        <motion.div
                          initial={{ opacity: 0, y: -15 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0 }}
                          key="dropdown"
                        >
                          <Avatar
                            className="size-7"
                            src={
                              profile?.avatar ||
                              maybeEnsAvatar ||
                              makeBlockie(address || "0x")
                            }
                            alt={address}
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </span>
                  {currentNavItem === "user" && (
                    <span
                      className={`
                        flex h-9 w-9 items-center justify-center rounded-full bg-sr-bg-tertiary duration-300
                        dark:hover:bg-sr-700
                        hover:bg-sr-400
                        lg:hidden lg:size-7
                      `}
                    >
                      <Cross1Icon />
                    </span>
                  )}
                </NavigationMenu.Trigger>
                <NavBarContent
                  onPointerDownOutside={handlePointerDownOutside}
                  className="lg:!w-[288px]"
                >
                  {address && <SubNavUserHeader address={address} />}
                  <div
                    className={`
                      flex flex-col gap-y-3 px-4 pt-3
                      lg:py-4
                    `}
                  >
                    {isConnected && (
                      <>
                        <AvatarWithDisplayName
                          avatarSrc={
                            profile?.avatar ||
                            maybeEnsAvatar ||
                            makeBlockie(address || "0x")
                          }
                          avatarAlt={address || "0x"}
                          profile={profile}
                        />
                        {!profile?.username && (
                          <CompleteProfileCta
                            className="mb-4"
                            onClick={resetNav}
                          />
                        )}
                        <HR />
                        {isWhitelisted && (
                          <>
                            <SubNavBarLink href={`/create`} onClick={resetNav}>
                              Create
                            </SubNavBarLink>
                          </>
                        )}
                        {profile && profile.username && (
                          <>
                            <SubNavBarLink
                              href={`/account/dashboard`}
                              onClick={resetNav}
                            >
                              Dashboard
                            </SubNavBarLink>
                            <SubNavBarLink
                              href="/account/settings"
                              onClick={resetNav}
                            >
                              Settings
                            </SubNavBarLink>
                            <HR />
                          </>
                        )}

                        <button
                          className={`
                            flex items-center gap-x-2 rounded-md p-2 text-base
                            hover:bg-sr-bg-tertiary hover:dark:bg-night-grey-4
                          `}
                          onClick={() => {
                            resetNav()
                            disconnect()
                          }}
                        >
                          Disconnect
                        </button>
                      </>
                    )}{" "}
                  </div>
                </NavBarContent>
              </NavigationMenu.Item>
            </>
          )}
          <NavigationMenu.Item
            value="connect"
            className={`
              hidden
              lg:order-last lg:block
            `}
          >
            <ConnectWalletButton />
          </NavigationMenu.Item>
        </NavigationMenu.List>
      </NavigationMenu.Root>
    </nav>
  )
}
