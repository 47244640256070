import { useAccount, useEnsName } from "wagmi"
import { EthformatAddress } from "./ethaddress"
import { match, P } from "ts-pattern"
import { useProfileStore } from "@/stores/profile"

export const DisplayName = () => {
  const { address } = useAccount()
  const { profile } = useProfileStore()
  const { data: maybeEnsName } = useEnsName({ address })

  return match([profile?.username, maybeEnsName, address])
    .with([P.string, P.any, P.any], ([username]) => username)
    .with([P.nullish, P.string, P.any], ([, ens]) => ens)
    .with([P.nullish, P.nullish, P.string], ([, , address]) =>
      EthformatAddress(address)
    )
    .otherwise(() => "0x0")
}
