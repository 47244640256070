import { Button } from "@/ui/button"

export const Newsletter = (): JSX.Element => {
  return (
    <section className="my-6 flex w-full flex-col items-start justify-start">
      <p className="mb-3 text-sm uppercase text-typography-grey-1">
        artworks & news to your inbox
      </p>
      <form
        action=""
        className={`
          relative flex w-full flex-col gap-y-1 rounded-md border-day-grey-2
          dark:border-night-grey-2 dark:bg-night-grey-5
          lg:w-8/12 lg:flex-row lg:border lg:bg-white lg:p-1
        `}
      >
        <input
          type="text"
          title="email"
          className={`
            w-full rounded-md border border-day-grey-2 bg-white p-2
            dim:bg-sr-900
            focus:outline-none
            lg:border-0 lg:bg-transparent lg:p-0 lg:pl-2
          `}
          placeholder="Email address..."
        />
        <Button kind="primary" uppercase type="submit">
          subscribe
        </Button>
      </form>
    </section>
  )
}
