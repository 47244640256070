import { useRemoveSalePrice } from "@/hooks/useRemoveSalePrice"
import { Input } from "@/ui/input"
import { FixedMobileButton } from "./SharedElements"
import { Loader } from "@/ui/loader"
import { weiToEth } from "@/utils/priceUtils"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"

export const RemovePriceContent = ({
  token,
  closeModal,
}: {
  token?: Erc721Token
  closeModal: () => void
}): JSX.Element => {
  const { isLoading, removeSalePrice } = useRemoveSalePrice({
    tokenId: token?.token_id as number,
    contractAddress: token?.contract_address as `0x${string}`,
  })
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <Input
        id="modal-transfer-amount"
        value={weiToEth(token?.current_sale_price?.[0]?.amount || 0) || 0}
        readOnly
        label="current list price"
      />
      <div>
        <hr />
        <FixedMobileButton
          onClick={() => removeSalePrice(closeModal)}
          disabled={isLoading}
        >
          {isLoading ? <Loader kind="simple" /> : "REMOVE LIST PRICE"}
        </FixedMobileButton>
      </div>
    </div>
  )
}
