import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { useRemoveAuction } from "@/hooks/useRemoveAuction"
import HR from "@/ui/hr"
import { FixedMobileButton } from "./SharedElements"
import { Loader } from "@/ui/loader"

export const CancelAuctionContent = ({
  token,
  closeModal,
}: {
  token: Erc721Token
  closeModal?: () => void
}): JSX.Element => {
  const { removeAuction, isLoading } = useRemoveAuction({
    contractAddress: token.contract_address as `0x${string}`,
    tokenId: token.token_id,
  })
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        <p className="text-sr-text-secondary">
          Are you sure you want to cancel this auction?
        </p>
      </div>
      <div className="mt-10">
        <HR />
        <FixedMobileButton
          onClick={() => removeAuction(closeModal)}
          disabled={isLoading}
        >
          {isLoading ? <Loader kind="simple" /> : "CANCEL AUCTION"}
        </FixedMobileButton>
      </div>
    </div>
  )
}
