import { useMakeOffer } from "@/hooks/useMakeOffer"
import { useProfileStore } from "@/stores/profile"
import { parseEther } from "viem"
import { useAccount, useBalance } from "wagmi"

import { weiToEth } from "@/utils/priceUtils"
import { InputWithCurrency } from "./InputWithCurrency"
import { isNumber } from "lodash"
import {
  ALLOWANCE_COPY,
  CurrencyDisplay,
  DataRow,
  FixedMobileButton,
  Terms,
  balanceMessage,
  symbolSwap,
} from "./SharedElements"
import { CurrencyBalanceFormat } from "../connectButton/connectButton"
import { Currency } from "@/utils/currency"
import HR from "@/ui/hr"
import { Loader } from "@/ui/loader"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { checkIsV1Token } from "@/utils/getTokenAbi"
import { useModal } from "@/stores/infoModal"

export const OfferContent = ({
  amount,
  setAmount,
  closeModal,
  selectedCurrency,
  setSelectedCurrency,
  token,
  approvedTerms,
  setApprovedTerms,
}: {
  amount: string | number
  setAmount: (amount: string | number) => void
  closeModal?: () => void
  selectedCurrency: Currency
  setSelectedCurrency: (currency: Currency["symbol"]) => void
  token: Erc721Token
  approvedTerms: boolean
  setApprovedTerms: (approvedTerms: boolean) => void
}): JSX.Element => {
  const { address } = useAccount()
  const { profile, rareBalance } = useProfileStore()
  const { data: balanceEth } = useBalance({
    address: address as `0x${string}`,
  })
  const { setType, setIsOpen } = useModal()
  const { isLoading, makeOffer, enoughAllowance } = useMakeOffer({
    token: token,
    weiPrice: Number(parseEther(String(amount))),
    currencyAddress: selectedCurrency.address as `0x${string}`,
  })
  const ethBalance = balanceEth?.formatted
  const isRare = selectedCurrency.symbol === "RARE"
  const hasEnoughBalance = isRare
    ? Number(weiToEth(parseInt(rareBalance.toString()))) >= Number(amount)
    : ethBalance && Number(ethBalance) >= Number(amount)
  const onOffer = () => {
    closeModal?.()
    if (!profile?.username) {
      setType("complete-profile")
      setIsOpen(true)
    }
  }
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        <InputWithCurrency
          id="modal-bid-amount"
          amount={amount}
          setAmount={setAmount}
          selectedCurrency={selectedCurrency}
          setSelectedCurrency={setSelectedCurrency}
          label="your offer"
          hasSuccess={isNumber(amount) && amount > 0}
          onlyEth
        />
        <div className="mt-6 gap-1">
          <DataRow label="Your balance">
            <CurrencyBalanceFormat
              className="text-sm uppercase text-sr-text-primary"
              balance={
                isRare
                  ? weiToEth(parseInt(rareBalance.toString()))
                  : ethBalance || 0
              }
              decimals={3}
              isFormatted
              symbol={symbolSwap(selectedCurrency.symbol)}
              symbolClass="text-sm"
            />
          </DataRow>
          <DataRow
            label="Total offer amount:"
            gray
            error={hasEnoughBalance ? undefined : balanceMessage(isRare)}
          >
            <CurrencyDisplay
              amount={amount}
              currency={selectedCurrency}
              withFee={!checkIsV1Token(token.contract_address, 1)}
            />
          </DataRow>
        </div>
      </div>
      <div className="mt-10">
        <HR />
        <p className="my-3 text-sm text-sr-text-secondary">
          Your {isRare ? "RARE" : "ether"}, including the marketplace fee, will
          be escrowed in the smart contract until your offer is accepted or you
          withdraw it.
        </p>
        {!profile?.username && (
          <Terms
            approvedTerms={approvedTerms}
            setApprovedTerms={setApprovedTerms}
          />
        )}
        <FixedMobileButton
          onClick={() => makeOffer(onOffer)}
          disabled={
            !!profile?.username &&
            !approvedTerms &&
            (isLoading ||
              amount === "" ||
              Number(amount) <= 0 ||
              !hasEnoughBalance)
          }
        >
          {isLoading ? (
            <Loader kind="simple" />
          ) : !enoughAllowance ? (
            ALLOWANCE_COPY
          ) : (
            "SUBMIT OFFER"
          )}
        </FixedMobileButton>
      </div>
    </div>
  )
}
