import {
  ALCHEMY_API_KEY_MAINNET,
  ALCHEMY_API_KEY_SEPOLIA,
} from "@/constants/environment"
import { createPublicClient, http } from "viem"
import { mainnet, sepolia } from "viem/chains"
import { Network, Alchemy } from "alchemy-sdk"
import { createConfig } from "wagmi"

export const clients = [
  {
    chain: mainnet,
    chainId: mainnet.id,
    viem: createPublicClient({
      batch: {
        multicall: true,
      },
      chain: mainnet,
      transport: http(
        `https://eth-mainnet.g.alchemy.com/v2/${ALCHEMY_API_KEY_MAINNET}`
      ),
    }),
    alchemy: new Alchemy({
      apiKey: ALCHEMY_API_KEY_MAINNET,
      network: Network.ETH_MAINNET,
    }),
  },
  {
    chain: sepolia,
    chainId: sepolia.id,
    viem: createPublicClient({
      batch: {
        multicall: true,
      },
      chain: sepolia,
      transport: http(
        `https://eth-sepolia.g.alchemy.com/v2/${ALCHEMY_API_KEY_SEPOLIA}`
      ),
    }),
    alchemy: new Alchemy({
      apiKey: ALCHEMY_API_KEY_SEPOLIA,
      network: Network.ETH_SEPOLIA,
    }),
  },
].map((c) => ({
  ...c,
  wagmiConfig: createConfig({
    chains: [c.chain],
    client() {
      return c.viem
    },
  }),
}))

export const getChainClients = (
  chainId: number
): {
  viem: ReturnType<typeof createPublicClient>
  alchemy: Alchemy
  wagmiConfig: ReturnType<typeof createConfig>
} => {
  const client = clients.find((c) => c.chainId === chainId)
  if (!client) {
    throw new Error(`No viem client found for chain ${chainId}`)
  }
  return client
}
