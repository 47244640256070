import { SuperRareV2Abi } from "@/contracts/superarrev2"
import { getSuperRareBazaar } from "@/contracts/superrarebazaar"
import { checkIsV1Token } from "@/utils/getTokenAbi"
import { useEffect, useMemo, useState } from "react"
import toast from "react-hot-toast"
import { useAccount, useConfig, useWriteContract } from "wagmi"
import { readContract } from "wagmi/actions"

export const useIsContractApproved = ({
  ownerAddress,
  contractAddress, //token address
}: {
  ownerAddress: string
  contractAddress: string
}): {
  isApproved: any
  approve: () => Promise<void | `0x${string}`>
  error: Error | undefined
  isLoading: boolean
} => {
  const { chain } = useAccount()
  const isV1Token = checkIsV1Token(contractAddress, chain?.id || 1)
  const [isApproved, setisApproved] = useState(isV1Token)
  const config = useConfig()
  const [isLoadingApproved, setIsLoadingApproved] = useState(false)
  const bazaarAddress = useMemo(
    () => getSuperRareBazaar(chain?.id || 1),
    [chain]
  )

  useEffect(() => {
    if (
      !contractAddress ||
      !ownerAddress ||
      !bazaarAddress ||
      isLoadingApproved ||
      isV1Token
    )
      return
    const hasApproved = async (): Promise<void> => {
      setIsLoadingApproved(true)
      const refetchdata = await readContract(config, {
        chainId: chain?.id,
        address: contractAddress as `0x${string}`,
        abi: SuperRareV2Abi,
        functionName: "isApprovedForAll",
        args: [ownerAddress as `0x${string}`, bazaarAddress],
      })
      setIsLoadingApproved(false)
      setisApproved(refetchdata as boolean)
    }
    hasApproved()
  }, [contractAddress, ownerAddress])

  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const approve = async (): Promise<`0x${string}` | void> => {
    if (isV1Token) return
    setIsLoadingApproved(true)
    try {
      if (!writeContractAsync) return
      const result = await writeContractAsync({
        address: contractAddress as `0x${string}`,
        abi: SuperRareV2Abi,
        functionName: "setApprovalForAll",
        args: [bazaarAddress, true],
        chainId: chain?.id,
      })
      result &&
        (setIsLoadingApproved(false),
        setisApproved(true),
        toast.success("Contract approved"))
      return result
    } catch (error) {}
  }
  return {
    isApproved: isApproved,
    error: errorContract as Error | undefined,
    isLoading: isLoadingApproved || isPending,
    approve,
  }
}
