import { useTransactionModalStore } from "@/stores/transactionModal"
import { zeroAddress } from "viem"
import { useSendTransaction, useWriteContract } from "wagmi"
import { abi } from "@/abis/RareToken"
import { genToastId } from "@/utils/toast"
import toast from "react-hot-toast"
import { TxToastError } from "@/utils/txUtils"
import { useState } from "react"

export const useSend = ({
  currencyAddress,
  weiAmount,
  toAddress,
}: {
  currencyAddress: `0x${string}`
  weiAmount: bigint
  toAddress: `0x${string}`
}): {
  send: (onSubmit?: () => void) => void
  isLoading: boolean
} => {
  const [isLoading, setIsLoading] = useState(false)
  const { setHash, setToastId } = useTransactionModalStore()
  const isEth = currencyAddress === zeroAddress

  const { sendTransactionAsync, error: errorTransaction } = useSendTransaction()

  const { writeContractAsync: sendTokenAsync, error: errorToken } =
    useWriteContract()

  const send = async (onSubmit?: () => void): Promise<void> => {
    if (!sendTransactionAsync || !sendTokenAsync) {
      return
    }
    setIsLoading(true)
    if (errorToken || errorTransaction) {
      toast.error("Transaction failed", { id: genToastId() })
      return
    }
    const randomId = genToastId()
    try {
      if (isEth) {
        toast.loading("Awaiting user confirmation...", { id: randomId })
        const tx = await sendTransactionAsync({
          to: toAddress,
          value: weiAmount,
        })
        tx && (setHash(tx), setToastId(randomId))
        tx && toast.loading("Transaction sent", { id: randomId })
        if (onSubmit) onSubmit()
      } else {
        // Handle non-ETH transactions here

        toast.loading("Awaiting user confirmation...", { id: randomId })
        const tx = await sendTokenAsync({
          address: currencyAddress,
          functionName: "transfer",
          abi: abi,
          args: [toAddress, weiAmount],
        })
        tx && (setHash(tx), setToastId(randomId))
        tx && toast.loading("Transaction sent", { id: randomId })
        if (onSubmit) onSubmit()
        setIsLoading(false)
      }
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
      setIsLoading(false)
    }
  }

  return {
    send,
    isLoading: isLoading,
  }
}
