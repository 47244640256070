import { useEffect, useState } from "react"
import useSWRMutation from "swr/mutation"
import { match, P } from "ts-pattern"

export type AuthenticationStatus = "AUTHENTICATED" | "UNAUTHENTICATED"

const validate = async () =>
  fetch("/api/next/auth/validate", {
    method: "POST",
    credentials: "include",
  })

const refresh = async () =>
  fetch("/api/next/auth/refresh", {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
  })
const checkAuthenticationStatus = async (): Promise<AuthenticationStatus> => {
  const response = await validate()

  if (response.status === 401) {
    const refreshResponse = await refresh()
    if (!refreshResponse.ok) {
      return "UNAUTHENTICATED"
    }
    const response2 = await validate()
    const data = await response2.json()
    return data
  }

  return "AUTHENTICATED"
}

export const useIsAuthenticated = () => {
  const {
    trigger: validationTrigger,
    data: validationData,
    error: validationError,
    isMutating: isValidationMutating,
    reset: resetAuthentication,
  } = useSWRMutation(`isAuthenticated`, checkAuthenticationStatus)
  const [isAuthenticated, setIsAuthenticated] = useState(false)

  useEffect(() => {
    match(validationData)
      .with(P.nullish, () => {
        setIsAuthenticated(false)
      })
      .with("AUTHENTICATED", () => {
        setIsAuthenticated(true)
      })
      .otherwise(() => {
        setIsAuthenticated(false)
      })
  }, [validationData])

  const reset = () => {
    resetAuthentication()
    setIsAuthenticated(false)
  }

  return {
    authenticate: validationTrigger,
    data: validationData,
    error: validationError,
    isMutating: isValidationMutating,
    reset,
    isAuthenticated,
  }
}
