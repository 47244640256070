import {
  SuperRareBazaarAbi,
  getSuperRareBazaar,
} from "@/contracts/superrarebazaar"
import { useTransactionModalStore } from "@/stores/transactionModal"
import { genToastId } from "@/utils/toast"
import { TxToastError } from "@/utils/txUtils"
import { useMemo } from "react"
import toast from "react-hot-toast"
import { zeroAddress } from "viem"
import { useAccount, useWriteContract } from "wagmi"

export const useRemoveSalePrice = ({
  contractAddress,
  tokenId,
  targetAddress,
}: {
  contractAddress: `0x${string}`
  tokenId: number
  targetAddress?: `0x${string}`
}): {
  removeSalePrice: (onSubmit?: () => void) => void
  isLoading: boolean
} => {
  const { chain } = useAccount()
  const bazarAddress = useMemo(
    () => getSuperRareBazaar(chain?.id || 1),
    [chain]
  )
  const randomId = genToastId()
  const target = targetAddress || zeroAddress
  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const { setHash, setToastId } = useTransactionModalStore()
  const removeSalePrice = async (onSubmit?: () => void): Promise<void> => {
    if (!writeContractAsync) {
      toast.error("Error: writeContractAsync " + errorContract)
      return
    }
    try {
      toast.loading("Awaiting confirmation...", { id: randomId })
      const result = await writeContractAsync({
        address: bazarAddress as `0x${string}`,
        abi: SuperRareBazaarAbi,
        functionName: "removeSalePrice",
        args: [contractAddress, BigInt(tokenId), target],
        chainId: chain?.id,
      })
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit && onSubmit()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return { removeSalePrice, isLoading: isPending }
}
