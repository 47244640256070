import React, { createContext, useContext, useEffect, useState } from "react"
import { motion } from "framer-motion"
import { usePathname, useRouter } from "next/navigation"
import clsx from "clsx"
import { cn } from "@/utils/cn"

const TabContext = createContext<{
  activeTab: string
  onTabChange: (value: string) => void
}>({
  activeTab: "",
  onTabChange: () => {},
})

interface TabProviderProps {
  children: React.ReactNode
  defaultTab: string
  onChange?: (value: string) => void
  keepState?: boolean
  className?: string
}

const TabProvider: React.FC<TabProviderProps> = ({
  children,
  defaultTab,
  onChange,
  keepState = false,
  className,
}) => {
  const router = useRouter()
  const [activeTab, setActiveTab] = useState(defaultTab || "")
  const pathname = usePathname()

  useEffect(() => {
    if (keepState) {
      const tabFromUrl = window.location.search.split("tab=")[1]
      if (tabFromUrl) {
        setActiveTab(tabFromUrl)
        onChange && onChange(tabFromUrl)
      }
    }
  }, [])

  useEffect(() => {
    if (!keepState && pathname !== activeTab) {
      setActiveTab(pathname)
    }
  }, [pathname])

  const handleTabChange = (value: string): void => {
    setActiveTab(value)
    onChange && onChange(value)
    if (keepState) {
      router.push(window.location.pathname + `?tab=${value}`)
    }
  }

  return (
    <TabContext.Provider value={{ activeTab, onTabChange: handleTabChange }}>
      <div
        className={clsx(
          "relative flex items-center justify-center overflow-hidden",
          className
        )}
      >
        {children}
      </div>
    </TabContext.Provider>
  )
}

interface TabProps {
  value: string
  children: React.ReactNode
  className?: string
  position?: "first" | "last" | "none"
  addSplitter?: boolean
}

const Tab: React.FC<TabProps> = ({
  value,
  children,
  className,
  position = "none",
  addSplitter = false,
}) => {
  const { activeTab, onTabChange } = useContext(TabContext)
  const motionDivClasses = clsx(
    `
      absolute -inset-y-[1px] z-10 border border-sr-border-primary bg-transparent
      dim:border-white
    `,
    {
      "ml-[-1px] w-[101%] rounded-l-md":
        position === "first" && value === activeTab,
      "ml-[-1px] w-[101%] rounded-r-md":
        position === "last" && value === activeTab,
    }
  )

  return (
    <button
      className={cn(
        `
          relative h-8 w-40 text-sm uppercase
          lg:w-48 lg:text-base
          ${
            value === activeTab
              ? `
                text-black
                dark:text-white
              `
              : "text-typography-grey-1"
          }
          ${
            (position === "none" || !position) && addSplitter
              ? `
                after:absolute after:right-[0] after:top-0 after:h-full after:w-px after:bg-sr-border-secondary
                after:content-[''] after:dim:bg-sr-border-tertiary
                before:absolute before:left-[0] before:top-0 before:h-full before:w-px before:bg-sr-border-secondary
                before:content-[''] before:dim:bg-sr-border-tertiary
              `
              : ""
          }
        `,
        className
      )}
      onClick={() => onTabChange(value)}
    >
      {children}
      {value === activeTab && (
        <motion.div className={motionDivClasses} layoutId="stacked" />
      )}
    </button>
  )
}

interface TabListProps {
  children?: React.ReactNode
  className?: string
}

const TabList: React.FC<TabListProps> = ({ children, className }) => {
  return (
    <nav
      className={cn(
        `
          flex rounded-md border border-sr-border-secondary
          dim:border-sr-border-tertiary
          lg:border
        `,
        className
      )}
    >
      {children}
    </nav>
  )
}

interface TabContentProps {
  value: string
  children: React.ReactNode
}

const TabContent: React.FC<TabContentProps> = ({ value, children }) => {
  const { activeTab } = useContext(TabContext)

  return value === activeTab ? <div className="pt-4">{children}</div> : null
}

export const StackedTabs = {
  Provider: TabProvider,
  Tab,
  List: TabList,
  Content: TabContent,
}
