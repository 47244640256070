import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { genToastId } from "@/utils/toast"
import { useMemo } from "react"
import toast from "react-hot-toast"
import { useAccount, useConfig, useWriteContract } from "wagmi"
import { getSuperRareBazaar } from "@/contracts/superrarebazaar"
import { getTxValueForBigInt } from "@/utils/priceUtils"
import { getAddress, zeroAddress } from "viem"
import { useSetMaxAllowance } from "./useSetMaxAllowance"
import { TxToastError } from "@/utils/txUtils"
import { useTransactionModalStore } from "@/stores/transactionModal"
import { getDefaultMarketplaceTokenAbi } from "@/utils/getTokenAbi"
import { getTokenPrice } from "@/utils/getTokenPrice"

type BuyToken = Pick<
  Erc721Token,
  "contract_address" | "current_sale_price" | "token_id" | "erc721_owner"
>

export const useBuy = ({
  token,
  currencyAddress,
  weiPrice,
}: {
  token: BuyToken
  marketAddress?: string
  weiPrice: number
  currencyAddress: string
}): {
  buyToken: (onSubmit?: () => void) => void
  isLoading: boolean
  enoughAllowance: boolean
} => {
  const { address, chain } = useAccount()
  const config = useConfig()
  const bazaarAddress = useMemo(
    () => getSuperRareBazaar(chain?.id || 1),
    [chain]
  )
  const tokenContractAddress = getAddress(token.contract_address)
  const tokenId = BigInt(token.token_id)
  const { setHash, setToastId } = useTransactionModalStore()
  const isEth = currencyAddress === zeroAddress

  const {
    writeContractAsync,
    isPending,
    error: errorContract,
  } = useWriteContract()
  const { allowance, isLoadingAllowance, setMaxAllowance } = useSetMaxAllowance(
    bazaarAddress,
    !!address &&
      !isEth &&
      !!chain &&
      getDefaultMarketplaceTokenAbi(tokenContractAddress, chain.id)._type !==
        "SuperRareV1"
  )

  const buyToken = async (onSubmit?: () => void): Promise<void> => {
    const randomId = genToastId()
    if (!writeContractAsync) {
      toast.error("Error: writeAsync " + errorContract)
      return
    }
    try {
      if ((!allowance || Number(allowance) < weiPrice) && !isEth) {
        toast.loading("Approving allowance...", { id: randomId })
        await setMaxAllowance()
        return
      }
      if (!chain) {
        toast.error("Error: chainId is not defined. ")
        return
      }
      const price = await getTokenPrice({
        address: tokenContractAddress,
        tokenId,
        chainId: chain.id,
        config,
      })

      const runTx = async () => {
        const abiDetails = getDefaultMarketplaceTokenAbi(
          tokenContractAddress,
          chain.id
        )
        switch (abiDetails._type) {
          case "SuperRareV1": {
            if (getAddress(currencyAddress) !== zeroAddress) {
              toast.error("Error: Only ETH is supported for V1 tokens")
            }
            return writeContractAsync({
              address: abiDetails.contract,
              abi: abiDetails.abi,
              functionName: "buy",
              args: [tokenId],
              value: price, // V1 already account for the 3%
            })
          }
          case "Bazaar": {
            return writeContractAsync({
              address: abiDetails.contract,
              abi: abiDetails.abi,
              functionName: "buy",
              args: [
                tokenContractAddress,
                tokenId,
                getAddress(currencyAddress),
                price,
              ],
              value: isEth ? getTxValueForBigInt(price) : undefined,
            })
          }
        }
      }
      toast.loading("Awaiting user confirmation...", { id: randomId })
      const result = await runTx()
      result && (setHash(result), setToastId(randomId))
      result && toast.loading("Transaction sent", { id: randomId })
      onSubmit?.()
    } catch (error) {
      const { message, options } = TxToastError(error as Error, randomId)
      toast.error(message, options)
    }
  }
  return {
    buyToken,
    isLoading: isPending || isLoadingAllowance,
    enoughAllowance: isEth || (!!allowance && allowance >= weiPrice),
  }
}
