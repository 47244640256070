import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import { useSettleAuction } from "@/hooks/useSettleAuction"
import HR from "@/ui/hr"
import { CurrencyDisplay, FixedMobileButton } from "./SharedElements"
import { formatEther, zeroAddress } from "viem"
import { Currency } from "@/utils/currency"
import { EthAddress } from "@/ui/ethaddress"

export const SettleAuctionContent = ({
  token,
  closeModal,
}: {
  token: Erc721Token
  closeModal?: () => void
}): JSX.Element => {
  const { settleAuction } = useSettleAuction({ token })
  const winner = token.nft_auctions?.[0]?.current_nft_auction_bid?.[0]?.bidder
  const amount = token.nft_auctions?.[0]?.current_nft_auction_bid?.[0]?.amount
  const currency = {
    symbol:
      token.nft_auctions?.[0]?.current_nft_auction_bid?.[0]?.currency?.type ||
      "ETH",
    address:
      token.nft_auctions?.[0]?.current_nft_auction_bid?.[0]?.currency
        ?.address || zeroAddress,
  } as Currency

  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        {winner && amount ? (
          <>
            <p className="mb-3 text-sm uppercase text-sr-text-secondary">
              Winning bid
            </p>
            <span className="inline-flex gap-1">
              <CurrencyDisplay
                amount={formatEther(BigInt(amount))}
                currency={currency}
              />
              <p className="text-sr-text-secondary">by</p>
              <p>
                @
                {winner.username || (
                  <EthAddress address={winner?.ethereum_address} />
                )}
              </p>
            </span>
          </>
        ) : null}
      </div>
      <div className="mt-10">
        <HR />
        <FixedMobileButton onClick={() => settleAuction(closeModal)}>
          SETTLE AUCTION
        </FixedMobileButton>
      </div>
    </div>
  )
}
