import { useDisconnect } from "wagmi"
import { useLogout } from "./useLogout"
import { useEffect } from "react"

export const useSRDisconnect = () => {
  const { logout } = useLogout()
  const { disconnect, status } = useDisconnect()

  const wrappedDisconnect = () => {
    if (status !== "pending") {
      disconnect()
    }
  }

  useEffect(() => {
    if (status === "pending") {
      logout()
    }
  }, [status])

  return { disconnect: wrappedDisconnect }
}
