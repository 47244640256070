import { SubNavBarLink } from "@/components/header/SubNavBarLink"

export const SubNavCuration = ({
  onClick,
  className,
}: {
  onClick?: () => void
  className?: string
}): JSX.Element => {
  return (
    <div
      className={`
        flex flex-col gap-y-0
        lg:p-4
        ${className ?? ""}
      `}
    >
      <SubNavBarLink onClick={onClick} href="/features">
        Features
      </SubNavBarLink>
      <SubNavBarLink href="https://insights.superrare.com/discovery/tag/curated-conversations">
        Curated Conversations
      </SubNavBarLink>
    </div>
  )
}
