import { useAccount } from "wagmi"
import { FixedMobileButton } from "./SharedElements"
import { Erc721Token } from "@/graphql/generated/apollo/graphql"
import HR from "@/ui/hr"
import { useTransfer } from "@/hooks/useTransferToken"
import { Input } from "@/ui/input"
import { isAddress } from "viem"

export const TransferContent = ({
  transferAddress,
  setTransferAddress,
  closeModal,
  token,
}: {
  transferAddress: string
  setTransferAddress: (amount: string) => void
  closeModal: () => void
  token: Erc721Token
}): JSX.Element => {
  const { address } = useAccount()
  const { transfer, isPending } = useTransfer({
    toAddress: transferAddress as `0x${string}`,
    fromAddress: address as `0x${string}`,
    tokenId: token.token_id,
    contractAddress: token.contract_address as `0x${string}`,
  })
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <Input
        id="modal-transfer-amount"
        value={transferAddress}
        onChange={(e) => setTransferAddress(e.target.value)}
        label="send to (wallet address)"
        hasSuccess={isAddress(transferAddress)}
        hasError={
          transferAddress.length >= 42 && !isAddress(transferAddress)
            ? { type: "pattern", message: "Invalid address" }
            : undefined
        }
      />
      <div className="mt-10">
        <HR />
        <p className="my-3 text-sm text-sr-text-secondary">
          Transfer your artwork to another wallet.
        </p>
        <FixedMobileButton
          onClick={() => transfer(closeModal)}
          disabled={isPending || !isAddress(transferAddress)}
        >
          TRANSFER
        </FixedMobileButton>
      </div>
    </div>
  )
}
