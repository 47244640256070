import moment from "moment"

export const formatDateTime = (
  date: string,
  time: string,
  unixTsSeconds: boolean = true
): number | undefined => {
  if (!date || !time) return
  const [hours, minutes] = time?.split(":").map(Number)
  const dateWithTime = moment(date, "MMMM Do, YYYY").toDate()

  dateWithTime.setHours(hours)
  dateWithTime.setMinutes(minutes)

  if (unixTsSeconds) return Math.floor(dateWithTime.getTime() / 1000)
  return dateWithTime.getTime()
}

export const convertDateToTimeAgo = (dateString: string): string => {
  const now = moment()
  const created = moment(dateString)
  const diff = now.diff(created)
  const duration = moment.duration(diff)

  if (duration.asDays() >= 1) {
    return `${Math.floor(duration.asDays())} day${Math.floor(duration.asDays()) > 1 ? "s" : ""} ago`
  } else {
    return `${duration.asHours()} hour${duration.asHours() > 1 ? "s" : ""} ago`
  }
}
