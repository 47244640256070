import React from "react"
import { FixedMobileButton, symbolSwap } from "./SharedElements"
import HR from "@/ui/hr"
import { useAccount, useBalance } from "wagmi"
import { useProfileStore } from "@/stores/profile"
import { Currency } from "@/utils/currency"
import { weiToEth } from "@/utils/priceUtils"
import { Input } from "@/ui/input"
import { useSend } from "@/hooks/useSend"
import { Loader } from "@/ui/loader"
import { parseEther } from "viem"
import { CurrencyBalanceFormat } from "../connectButton/connectButton"

interface Props {
  amount: string | number
  setAmount: (amount: string | number) => void
  transferAddress: string
  setTransferAddress: (amount: string) => void
  selectedCurrency: Currency
  closeModal?: () => void
}

export const SendContent = ({
  closeModal,
  selectedCurrency,
  amount,
  setAmount,
  setTransferAddress,
  transferAddress,
}: Props) => {
  const { address } = useAccount()
  const { data: balanceEth } = useBalance({
    address: address as `0x${string}`,
  })

  const ethBalance = balanceEth?.formatted
  const { rareBalance } = useProfileStore()
  const { send, isLoading } = useSend({
    currencyAddress: selectedCurrency.address,
    weiAmount: parseEther(String(amount)),
    toAddress: transferAddress as `0x${string}`,
  })
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (/[^\d,.]/.test(e.target.value)) return
    setAmount(e.target.value.replace(",", "."))
  }
  return (
    <div
      className={`
        flex h-full flex-col justify-between
        md:h-auto md:min-h-80
      `}
    >
      <div>
        <p className="text-sm text-sr-text-secondary">Your Balance</p>
        <CurrencyBalanceFormat
          className="text-sm uppercase text-sr-text-primary"
          balance={
            selectedCurrency.symbol === "RARE"
              ? weiToEth(Number(rareBalance))
              : ethBalance || 0
          }
          decimals={3}
          isFormatted
          symbol={symbolSwap(selectedCurrency.symbol)}
          symbolClass="text-sm"
        />
      </div>
      <Input
        id="modal-send-amount"
        value={amount}
        onChange={handleChange}
        label="send amount"
      />
      <Input
        id="modal-send-address"
        value={transferAddress}
        onChange={(e) => setTransferAddress(e.target.value)}
        label="send to (wallet address)"
      />
      <div className="mt-10">
        <HR />
        <FixedMobileButton
          disabled={isLoading}
          onClick={() => send(closeModal)}
        >
          {isLoading ? <Loader kind="simple" /> : "SEND"}
        </FixedMobileButton>
      </div>
    </div>
  )
}
