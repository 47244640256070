import HR from "@/ui/hr"
import { SocialIcons } from "../shared/SocialIcons"
import { SubNavBarLink } from "@/components/header/SubNavBarLink"
import { ConnectWalletButton } from "@/components/header/ConnectWalletButton"
import { SubNavCuration } from "@/components/header/SubNavCuration"

export const SubNavBurger = ({
  onClick,
}: {
  onClick: () => void
}): JSX.Element => {
  return (
    <>
      <ConnectWalletButton btnClassName="w-full lg:hidden" />
      <div
        className={`
          flex flex-col gap-y-0 px-4 pt-3
          lg:text-base
        `}
      >
        <div className="lg:hidden">
          <SubNavBarLink href="/explore" onClick={onClick}>
            Art
          </SubNavBarLink>
          <SubNavBarLink href="/feed" onClick={onClick}>
            Feed
          </SubNavBarLink>
          <SubNavBarLink href="/releases" onClick={onClick}>
            Releases
          </SubNavBarLink>
          <HR className="my-2" />
        </div>
        <SubNavCuration onClick={onClick} className="lg:hidden" />
        <div
          className={`
            z-20 pb-16
            lg:pb-0
          `}
        >
          <HR
            className={`
              my-2
              lg:hidden
            `}
          />
          <div className="flex flex-col gap-y-0">
            <SubNavBarLink onClick={onClick} href="/top-artists">
              Trending Artists
            </SubNavBarLink>
            <SubNavBarLink
              onClick={onClick}
              href="/top-collectors"
              className="lg:normal-case"
            >
              Trending Collectors
            </SubNavBarLink>
            <HR className="my-2" />
            <SubNavBarLink
              onClick={onClick}
              href="/airdrops"
              className="lg:normal-case"
            >
              $RARE Airdrops
            </SubNavBarLink>
            <SubNavBarLink
              onClick={onClick}
              href="https://rare.xyz/"
              className="lg:normal-case"
            >
              Rare Protocol
            </SubNavBarLink>
            <HR className="my-2" />
            <SubNavBarLink
              onClick={onClick}
              href="https://help.superrare.com/en/"
              className="lg:normal-case"
            >
              Help Center
            </SubNavBarLink>
            <HR className="my-2" />
          </div>
          <div
            className={`
              mb-3 mt-2 flex items-center gap-x-3
              lg:px-5
            `}
          >
            <SocialIcons />
          </div>
        </div>
      </div>
    </>
  )
}
